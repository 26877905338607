import * as React from "react";
import Spinner from "ui/Spinner";
import PropertyList from "c/PropertyList";
import { useHistory } from "react-router-dom";
import * as QueryString from "query-string";
import {
  Property,
  usePropertiesLazyQuery,
  usePropertiesQuery,
} from "generated/graphql";

type PropertiesProps = {};

// TODO: ival, managedProperties rate

const Properties: React.FC<PropertiesProps> = () => {
  const { data, loading } = usePropertiesQuery();
  const properties = data?.properties;

  const [
    getPropertiesWithParams,
    { loading: loadingPropertiesWithParams, data: propertiesWithParamsData },
  ] = usePropertiesLazyQuery();
  const propertiesWithParams = propertiesWithParamsData?.properties;

  const history = useHistory();
  const { search }: { search?: string } = QueryString.parse(
    history.location.search
  );

  React.useEffect(() => {
    if (!search) return;

    getPropertiesWithParams({
      variables: {
        input: { search },
      },
    });
  }, [getPropertiesWithParams, search]);

  if (loading || loadingPropertiesWithParams) return <Spinner />;

  return (
    <div className="flex max-h-full overflow-y-auto flex-1">
      {loading ? (
        <Spinner />
      ) : (
        <PropertyList
          properties={
            ((search ? propertiesWithParams : properties) as Property[]) ?? []
          }
        />
      )}
    </div>
  );
};

export default Properties;
