import * as React from "react";
import { IoPersonCircleSharp } from "react-icons/io5";
import classNames from "classnames/bind";
import styles from "./ProfileHeader.module.scss";
import { Maybe } from "graphql/jsutils/Maybe";

const cx = classNames.bind(styles);

type ProfileHeaderProps = {
  avatar?: Maybe<string>;
  name: string;
  onEditPhoto?: () => void;
  balance?: number;
  properties?: number;
};

const ProfileHeader: React.FC<ProfileHeaderProps> = ({
  avatar,
  name,
  onEditPhoto,
  balance,
  properties,
}) => {
  return (
    <>
      <div className="flex items-center relative">
        <div className="w-36 flex justify-center px-4">
          {avatar ? (
            <img
              alt={name}
              src={avatar}
              className="w-28 h-28 object-cover rounded-full"
            />
          ) : (
            <IoPersonCircleSharp size={100} />
          )}
        </div>
        <div className="font-bold text-3xl flex-1 text-center">{name}</div>
      </div>
      <div className="flex items-end text-center mb-8">
        <div className="w-36 px-4">
          <div
            className={cx(
              "text-gray-400 whitespace-nowrap text-sm cursor-pointer",
              {
                hidden: !onEditPhoto,
              }
            )}
            onClick={() => onEditPhoto?.()}
          >
            Edit photo
          </div>
        </div>
        <div className="w-36 px-4  border-r border-gray-400">
          <b>{balance || "-"}</b>
          <div className="text-gray-400 whitespace-nowrap text-sm">
            Current balance
          </div>
        </div>
        <div className="w-36 px-4">
          <b>{properties || "-"}</b>
          <div className="text-gray-400 whitespace-nowrap text-sm">
            Active Properties
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileHeader;
