import { gql } from "@apollo/client";

export const userFields = gql`
  fragment UserFields on User {
    id
    role
    email
    name
    isInvited
    isSignedUp
    avatar
    phone
  }
`;

export const companyFields = gql`
  fragment CompanyFields on Company {
    id
    logo
    name
  }
`;

export const propertyFields = gql`
  fragment PropertyFields on Property {
    id
    images {
      title
      url
    }
    name
    description
    status
    currentBalance
    unit
    streetNumber
    street
    palaceCode
    suburb
    city
    country
    availableDate
    bedrooms
    bathrooms
    floorArea
    geographicLocation
    landArea
    carParks
    startDate
    bondBalance
    documents {
      name
      url
    }
  }
`;

export const inspectionFields = gql`
  fragment InspectionFields on Inspection {
    attachments {
      name
      url
    }
    date
    description
    id
    issues {
      description
      attachments {
        name
        url
      }
    }
    matterportUrl
    youtubeUrl
    type
  }
`;

export const workOrderFields = gql`
  fragment WorkOrderFields on WorkOrder {
    description
    invoiceDescription
    id
    amount
    estimatedCost
    hasFeesExcluded
    jobAction
    notes
    priority
    scheduledDate
  }
`;

export const expenseFields = gql`
  fragment ExpenseFields on Expense {
    id
    name
    description
    accountType
  }
`;

export const supplierFields = gql`
  fragment SupplierFields on Supplier {
    id
    address
    changeCode
    comment
    companyDescription
    companyName
    contactFirstName
    contactLastName
    currentBalance
    dearName
    debits
    emails
    fax
    gstOrAbn
    initials
    insuranceExpiryDate
    invoiceNote
    isCompany
    lastTransactionDate
    mobile
    openingBalance
    payments
    paymentType
    phoneHome
    phoneWork
    serviceAddress
    servicesOffered
    statementMedia
    systemBankCode
    title
  }
`;

export const tenancyFields = gql`
  fragment TenancyFields on Tenancy {
    id
    bankCode
    bondNumber
    bondRequired
    changeCode
    contactAddress
    contactEmail
    contactFax
    contactPhones
    currentRent
    dateEnded
    dateStarted
    idDebtor
    lastPaymentDate
    leaseDateEnded
    lettingFeeRequired
    name
    note
    otherOwing
    outgoings
    rentalPeriod
    rentOwing
    rentPaidToDate
    rentPaidToLastMonth
    rentPartPayment
    sortCode
  }
`;

export const propertyManagerFields = gql`
  fragment PropertyManagerFields on PropertyManager {
    id
    propertyCount
    currentBalance
  }
`;

export const landlordFields = gql`
  fragment LandlordFields on Landlord {
    id
    propertyCount
    currentBalance
  }
`;

export const tenantFields = gql`
  fragment TenantFields on Tenant {
    id
    addressPostal
    addressService
    customExtras
    dearName
    email
    fax
    firstName
    lastName
    fullName
    isMainTenant
    initials
    paymentMethod
    phoneHome
    phoneMobile
    phoneWork
    propertyCount
  }
`;

export const palaceFields = gql`
  fragment PalaceFields on Palace {
    synchronizedAt
    id
    version
  }
`;

export const historicalUserStatisticsFields = gql`
  fragment HistoricalUserStatisticsFields on HistoricalUserStatistics {
    count
    date
    id
    type
  }
`;

export const paginationFields = gql`
  fragment PaginationFields on PaginationPayload {
    totalItems
    totalPages
  }
`;

export const messageFields = gql`
  fragment MessageFields on Message {
    id
    message
    plainText
    isSeen
    createdAt
    updatedAt
  }
`;

export const chatFields = gql`
  fragment ChatFields on Chat {
    id
  }
`;

export const officeFields = gql`
  fragment OfficeFields on Office {
    id
    address
    palace {
      ...PalaceFields
    }
  }
  ${palaceFields}
`;
