import * as React from "react";
import { useFormik } from "formik";
import Input from "c/Input";
import Button from "ui/Button";
import { useUpdateUserPasswordViaTokenMutation } from "generated/graphql";
import { useParams, useHistory } from 'react-router-dom';

type RouteParams = {
  token: string;
}

type ResetAccountPasswordProps = {
  //
};

const ResetAccountPassword: React.FC<ResetAccountPasswordProps> = () => {
  const history = useHistory();
  const [changePassword] = useUpdateUserPasswordViaTokenMutation()
  const { token } = useParams<RouteParams>()
  const [passwordSet, setPasswordSet] = React.useState<boolean>()
  const [passwordsDontMatch, setPasswordsDontMatch] = React.useState<boolean>()

  const { values, handleChange, handleSubmit, isSubmitting } = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: ""
    },
    onSubmit: async (data) => {
      console.log(data)
      if (data.confirmPassword !== data.newPassword) {
        setPasswordsDontMatch(true)
        return
      } 

      try {
        const { data: responseData } = await changePassword({
          variables: { 
            token: token, 
            password: data.newPassword 
          }
        })
      } catch (error) {
        console.error(error)

        return
      }
      setPasswordSet(true)
    },
  });

  const onChange = (event : any) => {
    setPasswordsDontMatch(false)
    handleChange(event)
  }

  // TODO: get reset token account + reset email
  return (
    <div>
      {!passwordSet && !isSubmitting && <>
        <form className="flex flex-col items-center justify-center" onSubmit={handleSubmit}>
          <p className="mb-4 text-xl">Enter your new password!</p>
          <Input
            value={values.newPassword}
            containerClassName="mb-4"
            name="newPassword"
            placeholder="password"
            onChange={onChange}
          />
          <Input
            value={values.confirmPassword}
            containerClassName="mb-4"
            name="confirmPassword"
            placeholder="repeat password"
            onChange={onChange}
          />
          <Button label="submit" type="submit" disabled={isSubmitting} className="mt-2" />
          {passwordsDontMatch && 
            <p className="text-red-500 mt-4 text-center">
              The two passwords do not match! Please enter <br/> matching passwords!
            </p>}
        </form>
      </>}
      {!passwordSet && isSubmitting && <p>Talking to server</p>}
      {passwordSet && <div className="flex flex-col items-center justify-center">
        <p>Your password has been set</p>
        <Button label="Login" onClick={() => {history.push('/')}} />
      </div>}
    </div>
  );
};

export default ResetAccountPassword;
