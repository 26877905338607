import * as chartjs from "chart.js";

interface IChartOptions {
  dataType: "default" | "price";
}

export const getChartOptions: (options: IChartOptions) => chartjs.ChartOptions =
  (options) => ({
    responsive: true,
    interaction: {
      intersect: false,
    },

    elements: {
      point: {
        radius: 0,
      },
    },
    legend: {
      display: false,
    },
    layout: {
      padding: {
        left: 5,
        right: 5,
        top: 5,
      },
    },
    tooltips: {
      callbacks: {
        label: ({ value }) => {
          const prefix = options.dataType === "price" ? "$" : "";
          return (
            prefix +
            Number(value).toLocaleString("en", {
              maximumFractionDigits: 2,
            })
          );
        },
      },
    },
    scales: {
      yAxes: [
        {
          display: false,
          ticks: {
            display: false,
          },
          gridLines: {
            display: false,
            drawBorder: false,
            zeroLineColor: "transparent",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false,
            zeroLineColor: "rgba(0, 0, 0, 0.1)",
          },
          ticks: {
            display: false,
          },
        },
      ],
    },
  });
