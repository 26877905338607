import * as React from "react";
import { IoMdSearch } from "react-icons/io";
import { ReactComponent as Logo } from "i/logo.svg";
import { RiBookletLine, RiDashboardLine } from "react-icons/ri";
import { AiOutlineHome } from "react-icons/ai";
import { BiGlassesAlt, BiHelpCircle } from "react-icons/bi";
import { IoLocationOutline, IoPeopleOutline } from "react-icons/io5";
import { CgArrowsExchange } from "react-icons/cg";
import { Link, NavLink, useHistory } from "react-router-dom";
import Tooltip from "rc-tooltip";
import { useApolloClient } from "@apollo/client";
import SelectOfficeOverlay from "./SelectOfficeOverlay";
import { UserRole, useUserQuery } from "generated/graphql";

type HeaderProps = {};

const Header: React.FC<HeaderProps> = () => {
  const client = useApolloClient();

  const { data: userData } = useUserQuery();
  const currentUser = userData?.user;

  const locationButtonRef = React.useRef(null);
  const history = useHistory();

  const [searchTerm, setSearchTerm] = React.useState("");

  const navbarItems = [
    {
      label: "Dashboard",
      icon: RiDashboardLine,
      path: "/dashboard",
      roles: [
        UserRole.Admin,
        UserRole.Landlord,
        UserRole.PropertyManager,
        UserRole.Tenant,
      ],
    },
    {
      label: "Properties",
      icon: AiOutlineHome,
      path: "/properties",
      roles: [UserRole.Admin, UserRole.Landlord, UserRole.PropertyManager],
    },
    {
      label: "Inspections",
      icon: BiGlassesAlt,
      path: "/inspections",
      roles: [UserRole.Admin, UserRole.Landlord, UserRole.PropertyManager],
    },
    {
      label: "Work Orders",
      icon: RiBookletLine,
      path: "/work-orders",
      roles: [UserRole.Admin, UserRole.Landlord, UserRole.PropertyManager],
    },
    {
      label: "Users",
      icon: IoPeopleOutline,
      path: "/users",
      roles: [UserRole.Admin, UserRole.PropertyManager, UserRole.Landlord],
    },
    {
      label: "Integrations",
      icon: CgArrowsExchange,
      path: "/integrations",
      roles: [UserRole.Admin],
    },
    {
      label: "Profile",
      icon: CgArrowsExchange,
      path: "/profile",
      roles: [
        UserRole.Admin,
        UserRole.Landlord,
        UserRole.PropertyManager,
        UserRole.Tenant,
      ],
    },
    {
      label: "Company",
      icon: CgArrowsExchange,
      path: "/company",
      roles: [UserRole.Admin],
    },
    {
      label: "Logout",
      icon: CgArrowsExchange,
      path: "/",
      onClick: async () => {
        localStorage.clear();

        client.resetStore();
        client.cache.gc();

        setTimeout(() => {
          history.push("/");
        }, 0);
      },
      roles: [
        UserRole.Admin,
        UserRole.Landlord,
        UserRole.PropertyManager,
        UserRole.Tenant,
      ],
    },
  ];

  //TODO: location overlay color fix, down

  return (
    <header className="flex items-center w-full h-24 px-10 bg-white ">
      <Link className="max-h-full py-2 " to="/">
        <Logo className="w-auto h-20 max-h-full" />
      </Link>

      <div className="text-gray-400">
        {currentUser?.role &&
          navbarItems
            .filter((i) => i.roles.includes(currentUser?.role))
            .map((i) =>
              i.onClick ? (
                <div
                  key={i.label}
                  onClick={i.onClick}
                  className="inline-block mx-4 font-semibold transition-all duration-300 cursor-pointer hover:text-black"
                >
                  {i.label}
                </div>
              ) : (
                <NavLink
                  to={i.path}
                  key={i.label}
                  activeClassName="text-black"
                  className="mx-4 font-semibold transition-all duration-300 hover:text-black"
                >
                  {i.label}
                </NavLink>
              )
            )}
      </div>
      {currentUser?.role &&
        [UserRole.Admin, UserRole.Landlord, UserRole.PropertyManager].includes(
          currentUser.role
        ) && (
          <>
            <Tooltip
              overlay={<SelectOfficeOverlay />}
              placement="bottomRight"
              trigger={["click", "hover"]}
              onPopupAlign={(node) => {
                if (locationButtonRef?.current) {
                  // for some reason simple check is not enough
                  const buttonBounds = (
                    locationButtonRef.current as unknown as Element
                  ).getBoundingClientRect();
                  const arrowEl = node.querySelector(
                    ".rc-tooltip-arrow"
                  ) as HTMLElement;
                  // 5-6 is the offset of size of triangle
                  if (arrowEl) {
                    arrowEl.style.right = `${(
                      buttonBounds.width / 2 -
                      6
                    ).toString()}px`;
                  }
                }
              }}
            >
              <div
                ref={locationButtonRef}
                className="ml-auto bg-gray-200 rounded-lg"
              >
                <IoLocationOutline size={30} className="p-1 " />
              </div>
            </Tooltip>
            <Link to="/help">
              <div className="ml-2 bg-gray-200 rounded-lg">
                <BiHelpCircle size={30} className="p-1 " />
              </div>
            </Link>
            <div className="flex items-center px-4 py-2 mx-2">
              <IoMdSearch size={20} className="mr-2" />
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  history.push({
                    pathname: "/properties",
                    search: `?search=${encodeURIComponent(searchTerm)}`,
                  });
                }}
              >
                <input
                  className="w-32 placeholder-black bg-transparent outline-none "
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </form>
            </div>
          </>
        )}
    </header>
  );
};

export default Header;
