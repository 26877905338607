import * as React from "react";
import classNames from "classnames/bind";
import styles from "./MessageList.module.scss";
import Message from "./Message/Message";
import { Message as IMessage } from "generated/graphql";

const cx = classNames.bind(styles);

type MessageListProps = {
  messages: Pick<IMessage, "message" | "user" | 'id'>[];
};

const MessageList: React.FC<MessageListProps> = ({ messages }) => {
  return (
    <div className={cx("flex flex-col overflow-y-auto flex-1 overflow-hidden")}>
      {messages.map((m, i) => {
        return (
          <Message
            key={m.id}
            message={m}
            isLastMessage={messages.length - 1 === i}
            isFirstMessage={i === 0}
          />
        );
      })}
    </div>
  );
};

export default MessageList;
