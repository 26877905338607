import { useReactiveVar } from "@apollo/client";
import ErrorsModal from "c/ErrorsModal";
import { errorsVar } from "index";
import * as React from "react";

type ErrorBoundaryProps = {
  children: React.ReactNode;
};

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  const errors = useReactiveVar(errorsVar);

  return (
    <>
      {errors.length > 0 && (
        <ErrorsModal
          errors={errors}
          onClose={() => {
            errorsVar([]);
          }}
        />
      )}
      {children}
    </>
  );
};

export default ErrorBoundary;
