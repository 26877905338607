import { useApolloClient } from "@apollo/client";
import Input from "c/Input";
import { useFormik } from "formik";
import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import Button from "ui/Button";
import Spinner from "ui/Spinner";
import { useInviteQuery, useSignUpUserMutation } from "generated/graphql";
import { currentOfficeVar } from "index";

type InviteSignUpProps = {};

const InviteSignUp: React.FC<InviteSignUpProps> = () => {
  const client = useApolloClient();
  const history = useHistory();
  const { token } = useParams<{ token: string }>();

  const { data, loading } = useInviteQuery({
    variables: {
      token,
    },
  });

  const [signupUser] = useSignUpUserMutation();

  const { handleChange, handleSubmit, values, isSubmitting } = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    onSubmit: async (fields) => {
      try {
        // TODO: password validation
        if (fields.password !== fields.confirmPassword) return;
        const { data: userData } = await signupUser({
          variables: {
            input: {
              token,
              password: fields.password,
            },
          },
        });

        localStorage.setItem("token", userData?.signUpUser.token ?? "");
        localStorage.setItem(
          "office",
          userData?.signUpUser?.user.company?.offices?.[0].id ?? ""
        );
        currentOfficeVar(
          userData?.signUpUser?.user.company?.offices?.[0].id ?? ""
        );

        client.refetchQueries({
          include: "all",
        });
      } catch (e) {
        console.error(e);
      }
    },
  });

  if (loading || isSubmitting) return <Spinner />;

  if (!data?.invite) {
    return (
      <div className="text-2xl font-semibold flex flex-col items-center">
        <div>Something went wrong!</div>
        <Button
          label="Login"
          className="mt-4"
          onClick={() => {
            history.push("/");
          }}
        />
      </div>
    );
  }

  const { email, name } = data.invite;

  return (
    <form
      onSubmit={handleSubmit}
      className="max-w-sm w-full px-8 py-8 flex flex-col justify-center items-center bg-white rounded-lg border border-gray-200"
    >
      <h1 className="text-2xl font-semibold mb-6">Welcome to Micasa</h1>
      <div className="text-3xl font-bold text-center">{name}</div>
      <div className="text-gray-400 mb-8 font-semibold">{email}</div>
      <div className="font-semibold mb-4">Please enter a password</div>
      <Input
        type="password"
        onChange={handleChange}
        value={values.password}
        name="password"
        containerClassName="mb-4 w-full"
        placeholder="Password"
      />
      <Input
        type="password"
        onChange={handleChange}
        value={values.confirmPassword}
        name="confirmPassword"
        containerClassName="mb-4 w-full"
        placeholder="Confirm Password"
        disabled={isSubmitting}
      />
      <Button className="my-4 px-8" label="Next" type="submit" />
    </form>
  );
};

export default InviteSignUp;
