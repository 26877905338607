import ProfileHeader from "c/ProfileHeader";
import { User, UserRole } from "generated/graphql";
import * as React from "react";
import { getRoleFields } from "u/getRoleFields";

const roleLabelMap: {
  [key in UserRole]: string;
} = {
  Admin: "Admin",
  Landlord: "Landlord",
  PropertyManager: "Property Manager",
  Tenant: "Tenant",
};

type SelectedUserProps = {
  user?: Pick<
    User,
    "role" | "landlord" | "tenant" | "propertyManager" | "name" | "phone"
  >;
};

const SelectedUser: React.FC<SelectedUserProps> = ({ user }) => {
  const roleData = (user ? getRoleFields(user) : {}) as {
    currentBalance?: number;
    propertyCount?: number;
  };
  return (
    <div className="relative flex-1 p-4 ml-4 bg-white rounded-xl">
      {user && (
        <>
          <ProfileHeader
            name={user.name}
            balance={roleData?.currentBalance}
            properties={roleData?.propertyCount}
          />
          <div className="flex mb-4">
            <div className="w-20 font-semibold">Phone</div>
            <div>{user.phone ?? "-"}</div>
          </div>
          <div className="flex mb-4">
            <div className="w-20 font-semibold">Role</div>
            <div>{roleLabelMap[user.role as UserRole]}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default SelectedUser;
