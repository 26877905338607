import * as React from "react";

type AuthLayoutProps = {
  children: (props?: {}) => React.ReactNode;
};

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  return (
    <div className="w-screen h-screen flex py-8 px-4 bg-light-gray justify-center items-center">
      {children({})}
    </div>
  );
};

export default AuthLayout;
