import * as React from "react";
import * as chartjs from "chart.js";
import * as dateFns from "date-fns";
import { ChartData, Line } from "react-chartjs-2";
import { getChartOptions } from "settings";
import HelpButton from "ui/HelpButton";
import Spinner from "ui/Spinner";
import { IHistoricalUserStatistic } from "interfaces";

type StatisticsCardProps = {
  data: IHistoricalUserStatistic[];
  loading: boolean;
  title: string;
  helpText: string;
  dataType?: "default" | "price";
  lineColor?: string;
  pointColor?: string;
};

const StatisticsCard: React.FC<StatisticsCardProps> = ({
  data,
  loading,
  title,
  helpText,
  dataType = "default",
  lineColor = "rgba(255, 99, 132, 0.2)",
  pointColor = "rgb(255, 99, 132)",
}) => {
  //TODO: make smooth

  const chartData: ChartData<chartjs.ChartData> = {
    labels: [],
    datasets: [
      {
        data: [],
        fill: false,
        backgroundColor: pointColor,
        borderColor: lineColor,
      },
    ],
  };

  let fromDate;
  let toDate;
  let latestValue;

  if (!loading && data.length >= 7) {
    const sortedHistory = [...data];

    sortedHistory
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
      .forEach(({ count, date }) => {
        chartData.labels?.push(date);
        chartData.datasets?.[0].data?.push(count);
      });
    fromDate = dateFns.format(new Date(sortedHistory[0].date), "d MMM");
    toDate = dateFns.format(
      new Date(sortedHistory[sortedHistory.length - 1].date),
      "d MMM"
    );
    latestValue = sortedHistory[sortedHistory.length - 1].count;

    const localeStringOptions: Intl.NumberFormatOptions = {
      maximumFractionDigits: 2,
    };

    latestValue = Number(latestValue).toLocaleString("en", localeStringOptions);
  }

  return (
    <div className="flex flex-col items-center justify-between w-full card">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="flex items-center mb-4 text-2xl font-semibold text-center">
            {title}
          </div>
          <HelpButton className="absolute right-5 top-5 " text={helpText} />

          {data.length < 7 ? (
            <div className="my-auto text-3xl font-semibold">
              {latestValue
                ? `${dataType === "price" ? "$" : ""}${latestValue}`
                : "Need More Data..."}
            </div>
          ) : (
            <>
              <div className="mb-2 text-3xl font-bold text-center">
                {`${dataType === "price" ? "$" : ""}${latestValue}`}
              </div>
              <Line
                data={chartData}
                options={getChartOptions({
                  dataType,
                })}
              />
              <div className="flex justify-between w-full font-semibold text-gray-400">
                <div>{fromDate}</div>
                <div>{toDate}</div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default StatisticsCard;
