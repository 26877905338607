import FileList from "c/FileList";
import { useFormik } from "formik";
import * as React from "react";
import Button from "ui/Button";

export interface IIssueForm {
  description: string;
  attachments: File[];
}

type IssueFormProps = {
  onAdd: (values: IIssueForm) => void;
};

const IssueForm = React.forwardRef<
  {
    getValues: () => IIssueForm;
  },
  IssueFormProps
>(({ onAdd }, ref) => {
  const { values, setFieldValue, handleChange, handleSubmit } =
    useFormik<IIssueForm>({
      initialValues: {
        description: "",
        attachments: [],
      },
      onSubmit: async (values) => {
        onAdd(values);
      },
    });

  React.useImperativeHandle(ref, () => ({
    getValues: () => values,
  }));

  return (
    <div>
      <textarea
        className="h-32 p-2 w-full border border-gray-300 rounded-lg outline-none"
        name="description"
        placeholder="description"
        onChange={handleChange}
        value={values.description}
      />
      <FileList
        files={values.attachments}
        onDrop={(files) => {
          setFieldValue("attachments", [...values.attachments, ...files]);
        }}
        onDelete={(files) => setFieldValue("attachments", files)}
      />
      <Button label="Add" className="mx-auto" onClick={() => handleSubmit()} />
    </div>
  );
});

export default IssueForm;
