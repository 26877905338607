import * as React from "react";
import { FileWithPath } from "file-selector";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import { AiOutlineFileAdd, AiOutlineFilePdf } from "react-icons/ai";
import { FiFileText } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { IAttachment } from "interfaces";
import classNames from "classnames/bind";
import styles from "./FileList.module.scss";
import { Attachment } from "generated/graphql";
import { Maybe } from "graphql/jsutils/Maybe";

const cx = classNames.bind(styles);

const imageExts = [".jpg", ".jpeg", ".gif", ".png", ".svg"];

interface FileListProps extends DropzoneOptions {
  files: (File | Maybe<Attachment>)[];
  onDelete?: (files: (File | Maybe<Attachment>)[]) => void;
  className?: string;
  canEdit?: boolean;
  canDownload?: boolean;
}

const getFilenameFromUrl = (url: string) => {
  if (url) {
    const m = url.toString().match(/.*\/(.+?)\./);
    if (m && m.length > 1) {
      return decodeURI(m[1]);
    }
  }
  return "";
};

const FileList: React.FC<FileListProps> = ({
  className,
  onDrop,
  onDelete,
  files = [],
  accept = "image/*,.pdf",
  multiple = false,
  canEdit = true,
  canDownload = false,
}) => {
  if (canEdit && !(onDelete && onDrop)) {
    throw new Error(
      `Please add onDelete or onDrop callbacks if canEdit is true`
    );
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    multiple,
  });

  return (
    <div className={cx(className, " pb-4 mb-2  overflow-x-auto ")}>
      <div className="border border-border-gray rounded-2xl  bg-light-gray flex w-min">
        {canEdit && (
          <div
            {...getRootProps()}
            className={cx(
              "flex items-center justify-center p-4   cursor-pointer outline-none ",
              {
                "mr-2": files.length,
              }
            )}
          >
            <input {...getInputProps()} />
            <AiOutlineFileAdd size={40} className="" />
          </div>
        )}
        {files.map((file) => {
          let Icon = FiFileText;
          const filePath: string =
            (file as IAttachment).url ??
            ((file as FileWithPath).path as string);

          const lastDotIndex = filePath.lastIndexOf(".");
          const ext = filePath.substr(lastDotIndex);
          const fileName = (file as IAttachment).url
            ? getFilenameFromUrl(filePath)
            : filePath.substr(0, lastDotIndex);
          const shortFileName = fileName.slice(0, 10);
          const formattedFileName = `${shortFileName}${
            shortFileName !== fileName ? "..." : "."
          }${ext}`;

          if (filePath.endsWith("pdf")) {
            Icon = AiOutlineFilePdf;
          }

          let image;
          let url = (file as IAttachment).url;
          if (url && !url.startsWith("https://")) {
            url = "https://" + url;
          }

          if (
            imageExts.some((ext) => filePath.toLocaleLowerCase().endsWith(ext))
          ) {
            image = (
              <img
                alt="file list item"
                className="max-h-10 mx-auto mb-2"
                src={
                  (file as IAttachment).url
                    ? url
                    : URL.createObjectURL(file as FileWithPath)
                }
              />
            );
          }

          return (
            <div
              key={filePath}
              className={cx(
                "p-4 mx-2  relative flex flex-col justify-end hover:bg-white rounded-2xl transition-all duration-300",
                styles.File
              )}
            >
              {canEdit && (
                <MdDelete
                  size={20}
                  onClick={() => {
                    const newFiles = files.filter((f) => f !== file);

                    onDelete?.(newFiles);
                  }}
                  className={cx(
                    styles.DeleteFile,
                    "absolute top-2 right-5 text-red-700 cursor-pointer hidden"
                  )}
                />
              )}
              {image ?? <Icon size={40} className="mx-auto mb-2 " />}
              <div className="text-sm text-very-dark-gray whitespace-nowrap">
                {canDownload && url ? (
                  <a href={url} target="_blank" rel="noreferrer">
                    {formattedFileName}
                  </a>
                ) : (
                  formattedFileName
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FileList;
