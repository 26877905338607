import * as React from "react";
import { format } from "date-fns";
import classNames from "classnames/bind";
import Button from "ui/Button";
import WorkOrderModal from "c/WorkOrderModal";
import { IWorkOrder } from "interfaces";
import Spinner from "ui/Spinner";
import { isAfter } from "date-fns";
import styles from "./WorkOrders.module.scss";
import { useWorkOrdersQuery } from "generated/graphql";

const cx = classNames.bind(styles);

type WorkOrdersProps = {
  className?: string;
  propertyId?: string;
  after?: Date;
};

const WorkOrders: React.FC<WorkOrdersProps> = ({
  className,
  propertyId,
  after,
}) => {
  const { data, loading } = useWorkOrdersQuery({
    variables: {
      pagination: {},
      propertyId,
    },
  });

  const [openedWordOrderId, setOpenedWordOrderId] = React.useState<
    string | null
  >(null);

  let content;

  if (loading) content = <Spinner />;

  const workOrdersForCurrentUser =
    data?.workOrders.workOrders.filter((w) => {
      if (!after) {
        return true;
      }
      return isAfter(new Date(w.scheduledDate), new Date(after));
    }) ?? [];

  if (workOrdersForCurrentUser.length === 0) {
    content = <div className="m-auto text-2xl">No Work Orders Yet</div>;
  }

  const openedWorkOrder =
    openedWordOrderId &&
    workOrdersForCurrentUser.find((i) => i.id === openedWordOrderId);

  if (!content) {
    content = (
      <div className={cx(className, "overflow-y-auto pr-4")}>
        {workOrdersForCurrentUser
          .filter((w) => {
            if (!after) {
              return true;
            }
            return isAfter(new Date(w.scheduledDate), new Date(after));
          })
          .map(({ description, id, jobAction, scheduledDate }) => {
            return (
              <div key={id} className="flex items-center my-4">
                <div className="flex-1 font-medium text-black line-clamp-1">
                  {description}
                </div>
                <div className="mx-4 text-center text-gray-400 w-44">
                  {scheduledDate
                    ? format(new Date(scheduledDate), "hh:mm aa dd/MM/yyyy")
                    : "-"}
                </div>

                <div
                  className={cx(
                    "ml-auto line-clamp-1 w-40 flex-shrink-0 text-center text-very-dark-gray"
                  )}
                >
                  {jobAction}
                </div>
                <Button
                  label="View"
                  padding="small"
                  className="font-medium px-4 mx-4 py-0.5"
                  onClick={() => setOpenedWordOrderId(id)}
                />
              </div>
            );
          })}
      </div>
    );
  }

  return (
    <div
      className={cx(
        "col-span-2  overflow-y-auto font-medium border border-border-gray p-6 rounded-2xl flex flex-col relative max-h-80"
      )}
    >
      {openedWordOrderId && (
        <WorkOrderModal
          onClose={() => setOpenedWordOrderId(null)}
          workOrder={openedWorkOrder as IWorkOrder}
        />
      )}
      {content}
    </div>
  );
};

export default WorkOrders;
