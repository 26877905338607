import ErrorBoundary from "c/ErrorBoundary";
import { BrowserRouter as Router } from "react-router-dom";
import RuntimeErrorBoundary from "c/RuntimeErrorBoundary";
import Routes from "./Routes";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { useSendFrontendErrorMutation } from "generated/graphql";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const App = () => {
  const [sendFrontendError] = useSendFrontendErrorMutation();

  const sendError = (error: string) => {
    sendFrontendError({
      variables: {
        error,
      },
    });
  };

  return (
    <RuntimeErrorBoundary sendError={sendError}>
      <Router>
        <ErrorBoundary>
          <Routes />
        </ErrorBoundary>
      </Router>
    </RuntimeErrorBoundary>
  );
};

export default App;
