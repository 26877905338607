import Textarea from "c/Textarea";
import { useFormik } from "formik";
import * as React from "react";
import Button from "ui/Button";
import Spinner from "ui/Spinner";
import { ReactComponent as AssistantImage } from "i/assistant.svg";
import { useSendHelpEnquiryMutation } from "generated/graphql";

type HelpCenterProps = {};

const HelpCenter: React.FC<HelpCenterProps> = () => {
  const [sendHelpEnquiry] = useSendHelpEnquiryMutation();

  const { values, handleChange, handleSubmit, isSubmitting, submitCount } =
    useFormik({
      initialValues: {
        message: "",
      },
      onSubmit: async (fields) => {
        sendHelpEnquiry({
          variables: fields,
        });
      },
    });

  if (isSubmitting) return <Spinner />;

  return (
    <div className="flex flex-col flex-1 items-center justify-center">
      <AssistantImage className="mb-6" />
      {submitCount === 0 ? (
        <>
          <div className="text-3xl font-semibold my-4">
            What can we help you with?
          </div>
          <form
            className="w-full max-w-xl flex flex-col"
            onSubmit={handleSubmit}
          >
            <Textarea
              className="mx-3 mt-4 self-stretch "
              name="message"
              placeholder="Please describe your problem"
              onChange={handleChange}
              value={values.message}
            />
            <Button label="Submit" type="submit" className="self-center mt-4" />
          </form>
        </>
      ) : (
        <div className="text-3xl font-semibold ">
          Thank you for your enquiry! We'll come back to you soon.
        </div>
      )}
    </div>
  );
};

export default HelpCenter;
