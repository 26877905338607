import Input from "c/Input";
import { useFormik } from "formik";
import { useCompanyQuery, useUpdateCompanyMutation } from "generated/graphql";
import * as React from "react";
import Button from "ui/Button";
import Spinner from "ui/Spinner";
import UploadFile from "ui/UploadFile";

type CompanyProps = {
  //
};

interface ICompanyForm {
  name: string;
  logo: string | File;
}

const Company: React.FC<CompanyProps> = () => {
  const { data, loading } = useCompanyQuery();
  const [updateCompany] = useUpdateCompanyMutation();

  const company = data?.company;
  const { values, handleChange, handleSubmit, setFieldValue, isSubmitting } =
    useFormik<ICompanyForm>({
      initialValues: {
        name: "",
        logo: "",
      },
      onSubmit: async (values) => {
        if (!values.logo || !values.name) return;

        const updatedFields: Partial<ICompanyForm> = {};
        values.logo !== company?.logo && (updatedFields.logo = values.logo);
        values.name !== company?.name && (updatedFields.name = values.name);
        updateCompany({
          variables: { input: updatedFields },
        });
      },
    });

  React.useEffect(() => {
    if (!company) return;

    !values.logo && setFieldValue("logo", company.logo);
    !values.name && setFieldValue("name", company.name);
  }, [company, setFieldValue, values]);

  if (loading) return <Spinner />;

  return (
    <div className="flex flex-col items-center justify-center flex-1 w-full h-full ">
      <div className="flex flex-col p-6 px-10 py-10 bg-white border rounded-3xl border-border-gray">
        <div className="mb-2 font-bold">Company Logo:</div>
        <UploadFile
          className="bg-gray-100"
          accept="image/*"
          files={[values.logo] as File[] | string[]}
          onDrop={(files) => {
            setFieldValue("logo", files[0]);
          }}
        />
        <div className="mb-8 ">
          <div className="mb-2 font-bold">Company Name</div>
          <Input
            value={values.name}
            name="name"
            onChange={handleChange}
            placeholder="Company Name"
          />
        </div>
        <Button
          disabled={isSubmitting}
          label="save"
          className="self-center"
          onClick={() => handleSubmit()}
        />
      </div>
    </div>
  );
};

export default Company;
