import * as React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { Icon, LatLngExpression } from "leaflet";
import _ from "lodash";
import Spinner from "ui/Spinner";
import Swiper from "react-id-swiper";
import { getPropertyAddress } from "u/getPropertyAddress";
import "leaflet/dist/leaflet.css";
import Button from "ui/Button";
import { useHistory } from "react-router-dom";
import { IProperty } from "interfaces";
import { useCompanyQuery, usePropertiesQuery } from "generated/graphql";

type PropertiesMapProps = {};

const PropertiesMap: React.FC<PropertiesMapProps> = () => {
  const history = useHistory();
  // const { properties = [], loading } = useProperties();
  const { data: propertiesData, loading: propertiesLoading } =
    usePropertiesQuery();

  const { data: companyData, loading: companyLoading } = useCompanyQuery();

  const company = companyData?.company;

  const propertiesWithUniqueCoordinates = _.uniqBy(
    propertiesData?.properties ?? [],
    "geographicLocation"
  ).filter(({ geographicLocation }) => {
    if (!geographicLocation) return false;
    if (
      Number.isNaN(parseFloat(geographicLocation.split(":")[0])) ||
      Number.isNaN(parseFloat(geographicLocation.split(":")[1]))
    ) {
      return false;
    }
    return true;
  }) as IProperty[];

  const mapCenter = {
    longitude: (propertiesWithUniqueCoordinates.reduce(
      (acc, { geographicLocation: coord }) => {
        if (!coord?.split(":")[0]) {
          return acc;
        }
        return acc + parseFloat(coord.split(":")[0]);
      },
      0
    ) / propertiesWithUniqueCoordinates.length) as number,
    latitude: (propertiesWithUniqueCoordinates.reduce(
      (acc, { geographicLocation: coord }) => {
        if (!coord?.split(":")[1]) {
          return acc;
        }
        return acc + parseFloat(coord.split(":")[1]);
      },
      0
    ) / propertiesWithUniqueCoordinates.length) as number,
  };

  const defaultMapCenter: LatLngExpression = [-41.2924, 174.7787];

  return (
    <div className="relative w-full col-span-4 overflow-hidden h-96 rounded-2xl">
      {propertiesLoading && companyLoading ? (
        <Spinner />
      ) : (
        <MapContainer
          className="absolute top-0 left-0 w-full h-full"
          center={
            propertiesWithUniqueCoordinates.length > 0
              ? [mapCenter.longitude, mapCenter.latitude]
              : defaultMapCenter
          }
          zoom={10}
          scrollWheelZoom={true}
        >
          <TileLayer
            zIndex={0}
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://api.mapbox.com/styles/v1/olegverum/ckmd56zvl2a0y17odt3u1ly08/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoib2xlZ3ZlcnVtIiwiYSI6ImNrbTdnMmdydDBiZjcycG51ODFzNm85bDMifQ.AoprKbQlo3CYEwe_cR0mQw"
          />
          {propertiesWithUniqueCoordinates.map((p) => {
            if (!p.geographicLocation) return null;
            return (
              <Marker
                position={
                  p.geographicLocation
                    .split(":")
                    .map((coordinate) => Number(coordinate)) as [number, number]
                }
                {...(company?.logo && {
                  icon: new Icon({
                    iconUrl: company.logo,
                    className: "h-2 object-contain",
                  }),
                })}
                key={p.geographicLocation}
              >
                <Popup>
                  <>
                    {p.images.length > 0 && (
                      <Swiper
                        slidesPerView="auto"
                        pagination={{
                          el: ".swiper-pagination",
                          clickable: true,
                          dynamicBullets: true,
                        }}
                        loop
                        containerClass="h-48 overflow-hidden relative w-full"
                        slideClass="w-auto"
                        spaceBetween={25}
                        navigation={{
                          nextEl: ".swiper-button-next",
                          prevEl: ".swiper-button-prev",
                        }}
                      >
                        {p.images.map(({ url }) => (
                          <img
                            key={url}
                            src={url}
                            alt="address"
                            className="w-auto h-48 rounded-2xl"
                          />
                        ))}
                      </Swiper>
                    )}
                    {getPropertyAddress(p)}
                    <Button
                      label="View More"
                      className="mx-auto mt-2"
                      onClick={() => history.push(`/properties/${p.id}`)}
                    />
                  </>
                </Popup>
              </Marker>
            );
          })}
        </MapContainer>
      )}
    </div>
  );
};

export default PropertiesMap;
