import * as React from "react";
import classNames from "classnames/bind";
import styles from "./Button.module.scss";

const cx = classNames.bind(styles);

interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  label: string;
  className?: string;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  padding?: string;
  color?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { label, className, type, padding, color = "default", ...buttonProps },
    ref
  ) => {
    return (
      <button
        ref={ref}
        {...buttonProps}
        type={type ?? "button"}
        className={cx(
          " border  rounded-xl  outline-none block transition font-semibold duration-150",
          className,
          {
            "px-4 py-0.5": padding === "small",
            "px-4 py-1": padding !== "small",
            "bg-purple border-light-purple text-white": color === "default",
            "bg-medium-gray border-border-gray hover:bg-very-dark-gray hover:text-white":
              color === "gray",
            "bg-pink border-dark-pink text-white": color === "pink",
          }
        )}
      >
        {label}
      </button>
    );
  }
);

export default Button;
