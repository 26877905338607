import { format } from "date-fns";
import { IWorkOrder } from "interfaces";
import * as React from "react";
import { getPropertyAddress } from "u/getPropertyAddress";
import Modal from "ui/Modal";
import { ModalProps } from "ui/Modal/Modal";

interface WorkOrderModalProps extends ModalProps {
  workOrder: IWorkOrder;
}

const WorkOrderModal: React.FC<WorkOrderModalProps> = ({
  onClose,
  workOrder,
}) => {
  return (
    <Modal {...{ onClose }} className="overflow-auto">
      <div className="flex my-4">
        <div className="font-semibold w-48  flex-shrink-0">Description:</div>
        <div>{workOrder.description}</div>
      </div>
      <div className="flex my-4">
        <div className="font-semibold w-48  flex-shrink-0">
          Invoice Description:
        </div>
        <div>{workOrder.invoiceDescription}</div>
      </div>
      <div className="flex my-4">
        <div className="font-semibold w-48  flex-shrink-0">Amount:</div>
        <div>{workOrder.amount || "-"}</div>
      </div>
      <div className="flex my-4">
        <div className="font-semibold w-48  flex-shrink-0">Estimated Cost:</div>
        <div>{workOrder.estimatedCost || "-"}</div>
      </div>
      <div className="flex my-4">
        <div className="font-semibold w-48  flex-shrink-0">Fees Excluded:</div>
        <div>{workOrder.hasFeesExcluded ? "yes" : "no"}</div>
      </div>
      <div className="flex my-4">
        <div className="font-semibold w-48  flex-shrink-0">Job Action:</div>
        <div>{workOrder.jobAction}</div>
      </div>
      <div className="flex my-4">
        <div className="font-semibold w-48  flex-shrink-0">Notes:</div>
        <div>{workOrder.notes ?? "-"}</div>
      </div>
      <div className="flex my-4">
        <div className="font-semibold w-48  flex-shrink-0">Priority:</div>
        <div>{workOrder.priority}</div>
      </div>
      <div className="flex my-4">
        <div className="font-semibold w-48  flex-shrink-0">Scheduled Date:</div>
        <div>
          {workOrder.scheduledDate
            ? format(new Date(workOrder.scheduledDate), "dd/MM/yyyy")
            : "-"}
        </div>
      </div>
      <div className="flex my-4">
        <div className="font-semibold w-48  flex-shrink-0">
          Property Address:
        </div>
        <div>{getPropertyAddress(workOrder.property)}</div>
      </div>
      <div className="flex my-4">
        <div className="font-semibold w-48  flex-shrink-0">
          Property Manager:
        </div>
        <div>{workOrder.propertyManager?.user?.name ?? "-"}</div>
      </div>
      <div className="flex my-4">
        <div className="font-semibold w-48  flex-shrink-0">Expense Name:</div>
        <div>{workOrder.expense?.name ?? "-"}</div>
      </div>
      <div className="flex my-4">
        <div className="font-semibold w-48  flex-shrink-0">
          Expense Description:
        </div>
        <div>{workOrder.expense?.description ?? "-"}</div>
      </div>
      <div className="flex my-4">
        <div className="font-semibold w-48  flex-shrink-0">Supplier:</div>
        <div>{workOrder.supplier?.companyName ?? "-"}</div>
      </div>
      <div className="flex my-4">
        <div className="font-semibold w-48  flex-shrink-0">Tenancy:</div>
        <div>{workOrder.tenancy?.name ?? "-"}</div>
      </div>
    </Modal>
  );
};

export default WorkOrderModal;
