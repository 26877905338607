import * as React from "react";
import ProgressBar from "ui/ProgressBar";

type EstimatedValuationProps = {};

const EstimatedValuation: React.FC<EstimatedValuationProps> = () => {
  return (
    <div className="text-center flex flex-col justify-center items-center">
      <div className="text-gray-400 font-semibold text-2xl">Estimate iVal</div>
      <div className="text-4xl font-bold my-6">$-</div>
      <ProgressBar />
    </div>
  );
};

export default EstimatedValuation;
