import * as React from "react";
import { ReactComponent as SpinnerIcon } from "i/spinner.svg";
import classNames from "classnames/bind";
import styles from "./Spinner.module.scss";

const cx = classNames.bind(styles);

type SpinnerProps = {
  position?: string;
};

const Spinner: React.FC<SpinnerProps> = ({ position = "absolute" }) => {
  return (
    <SpinnerIcon
      className={cx({
        "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2":
          position === "absolute",
      })}
    />
  );
};

export default Spinner;
