import * as React from "react";
import { IoMdAdd } from "react-icons/io";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap_white.css";
import { UserRole, useUserQuery } from "generated/graphql";

//TODO: maybe change the pattern as it's any type right now.

type FloatingButtonProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  overlay: React.FC<any>;
};

const FloatingButton: React.FC<FloatingButtonProps> = ({
  overlay: Overlay,
  ...props
}) => {
  const buttonRef = React.useRef(null);

  const { data: userData } = useUserQuery();
  const currentUser = userData?.user;
  if (
    currentUser &&
    [UserRole.Tenant, UserRole.Landlord].includes(currentUser.role)
  )
    return null;

  return (
    <Tooltip
      overlay={<Overlay {...{ ...props, currentUser }} />}
      placement="topLeft"
      trigger={["click", "hover"]}
      onPopupAlign={(node) => {
        if (buttonRef?.current) {
          // for some reason simple check is not enough
          const buttonBounds = (
            buttonRef.current as unknown as Element
          ).getBoundingClientRect();
          const arrowEl = node.querySelector(
            ".rc-tooltip-arrow"
          ) as HTMLElement;
          // 5-6 is the offset of size of triangle

          if (arrowEl) {
            arrowEl.style.right = `${(
              buttonBounds.width / 2 -
              6
            ).toString()}px`;
          }
        }
      }}
    >
      <div
        ref={buttonRef}
        className="absolute border rounded-full shadow-2xl cursor-pointer bg-pink hover:bg-dark-pink border-dark-pink bottom-4 right-4"
      >
        <IoMdAdd size={50} className="text-white" />
      </div>
    </Tooltip>
  );
};

export default FloatingButton;
