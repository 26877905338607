import { useReactiveVar } from "@apollo/client";
import Input from "c/Input";
import { useFormik } from "formik";
import {
  useOfficeQuery,
  useUpdatePalaceAccountMutation,
} from "generated/graphql";
import { currentOfficeVar } from "index";
import * as React from "react";
import Button from "ui/Button";
import Modal from "ui/Modal";
import { ModalProps } from "ui/Modal/Modal";

// TODO: need to double check that with clear db
interface AddEditPalaceProps extends ModalProps {}

const AddEditPalace: React.FC<AddEditPalaceProps> = ({ onClose }) => {
  const currentOfficeId = useReactiveVar(currentOfficeVar);
  const [updatePalace] = useUpdatePalaceAccountMutation();

  const { refetch: refetchOffice } = useOfficeQuery({
    skip: !currentOfficeId,
  });

  const { handleChange, values, submitForm } = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: async (fields) => {
      await updatePalace({
        variables: {
          input: fields,
        },
      });
      onClose();
      refetchOffice();
    },
  });

  return (
    <Modal onClose={onClose}>
      <form onSubmit={submitForm}>
        <Input
          onChange={handleChange}
          value={values.username}
          name="username"
          containerClassName="mb-4"
          placeholder="Username*"
        />
        <Input
          type="password"
          onChange={handleChange}
          value={values.password}
          name="password"
          containerClassName="mb-4"
          placeholder="Password*"
        />
        <Button label="Edit" className="mx-auto" type="submit" />
      </form>
    </Modal>
  );
};

export default AddEditPalace;
