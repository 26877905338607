import * as React from "react";
import Modal from "ui/Modal";
import UploadFile from "ui/UploadFile";
import classNames from "classnames/bind";
import Spinner from "ui/Spinner";
import styles from "./UploadNewPhoto.module.scss";
import { useSetAvatarMutation } from "generated/graphql";

const cx = classNames.bind(styles);

type UploadNewPhotoProps = {
  onClose: () => void;
};

const UploadNewPhoto: React.FC<UploadNewPhotoProps> = ({ onClose }) => {
  const [setAvatar, { loading }] = useSetAvatarMutation();

  return (
    <Modal {...{ onClose }}>
      {loading && <Spinner />}
      <UploadFile
        onDrop={async (files) => {
          await setAvatar({
            variables: {
              image: files[0],
            },
          });
          onClose();
        }}
        className={cx("", { invisible: loading })}
      />
    </Modal>
  );
};

export default UploadNewPhoto;
