import * as React from "react";
import propertyPlaceholder from "i/property-placeholder-image.svg";
import { Link } from "react-router-dom";
import { getPropertyAddress } from "u/getPropertyAddress";
import { formatPrice } from "u/formatPrice";
import classNames from "classnames/bind";
import styles from "./PropertyList.module.scss";
import { Property } from "generated/graphql";

const cx = classNames.bind(styles);

type PropertyListProps = {
  properties: Property[];
};
//TODO: make padding at the bottom
const PropertyList: React.FC<PropertyListProps> = ({ properties }) => {
  return properties?.length > 0 ? (
    <div
      className={cx(
        "flex-1 grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 px-2 mr-4 rounded-lg  pb-40"
      )}
    >
      {properties.map((property) => {
        return (
          <Link to={`/properties/${property.id}`} key={property.id}>
            <div
              className={cx(
                styles.Property,
                "flex flex-col  rounded-lg overflow-hidden"
              )}
            >
              <div
                className={cx(
                  styles.ThumbnailContainer,
                  "flex-1 w-full rounded-t-lg relative overflow-hidden"
                )}
              >
                <img
                  className="absolute top-0 object-cover w-full h-full transition-all duration-1000 ease-in-out"
                  src={property.images[0]?.url ?? propertyPlaceholder}
                  alt={property.images[0]?.title ?? ""}
                />
              </div>
              <div
                className={cx(
                  styles.ContentContainer,
                  "flex flex-col py-4 px-6 bg-white transition-all duration-300"
                )}
              >
                <div className="flex my-4">
                  <div className="flex-1 text-lg font-semibold line-clamp-2 h-14">
                    {getPropertyAddress(property)}
                  </div>
                </div>
                <div className={cx(styles.DescriptionContainer)}>
                  <div className="flex justify-between pb-1 mb-1">
                    <div className="font-semibold">Property Code</div>
                    <div>{property.palaceCode}</div>
                  </div>
                  <div className="flex justify-between my-1">
                    <div className="font-semibold">Balance</div>
                    <div>{formatPrice(property.currentBalance, true)}</div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  ) : (
    <div className="flex items-center justify-center flex-1 col-span-2 text-3xl text-center">
      <div>No Properties Found</div>
    </div>
  );
};

export default PropertyList;
