import * as React from "react";
import FloatingButton from "ui/FloatingButton";
import AddOfficeModal from "c/AddOfficeModal";
import Header from "./Header";
import Menu from "./Menu";
import AddOverlayFloatingMenu from "./AddOverlayFloatingMenu";
import AddEditInspection from "./AddEditInspection";

type MainLayoutProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: (props?: any) => React.ReactNode;
};

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);
  const [isInspectionModalOpen, setIsInspectionModalOpen] =
    React.useState<boolean>(false);
  const [isAddOfficeModalOpen, setIsAddOfficeModalOpen] =
    React.useState<boolean>(false);

  return (
    <div className="flex flex-col w-screen h-screen max-h-screen overflow-hidden max-w-screen">
      <Header />
      <Menu isOpen={isMenuOpen} setIsOpen={setIsMenuOpen} />
      <div className="relative z-0 flex flex-1 h-full px-10 pt-6 overflow-hidden">
        {children()}
      </div>
      {isInspectionModalOpen && (
        <AddEditInspection onClose={() => setIsInspectionModalOpen(false)} />
      )}
      {isAddOfficeModalOpen && (
        <AddOfficeModal onClose={() => setIsAddOfficeModalOpen(false)} />
      )}
      <FloatingButton
        overlay={AddOverlayFloatingMenu}
        {...{
          isInspectionModalOpen,
          setIsInspectionModalOpen,
          isAddOfficeModalOpen,
          setIsAddOfficeModalOpen,
        }}
      />
    </div>
  );
};

export default MainLayout;
