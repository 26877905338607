import * as React from "react";
import { Redirect, useHistory } from "react-router-dom";
import "rc-collapse/assets/index.css";

import AddOffice from "c/AddOffice";
import {
  useCheckPalaceConnectionMutation,
  useCreateCompanyMutation,
  useCreateOfficeMutation,
  useCreatePalaceMutation,
  useUserQuery,
} from "generated/graphql";
import { useApolloClient } from "@apollo/client";
import { currentOfficeVar } from "index";

type CompanySetupProps = {};

const CompanySetup: React.FC<CompanySetupProps> = () => {
  const client = useApolloClient();

  const [createOffice] = useCreateOfficeMutation();
  const [checkPalaceConnection] = useCheckPalaceConnectionMutation();
  const [createPalace] = useCreatePalaceMutation();
  const [createCompany] = useCreateCompanyMutation();

  const { data } = useUserQuery();

  const history = useHistory();

  if (!data?.user) return <Redirect to="/signup" />;

  return (
    <div className="max-w-md w-full px-10 py-10 flex flex-col justify-center items-center bg-white rounded-2xl border border-border-gray">
      <h1 className="text-2xl font-semibold mb-6">Company Setup</h1>
      <AddOffice
        onSubmit={async (values) => {
          try {
            await checkPalaceConnection({
              variables: {
                input: {
                  username: values.palace.username,
                  password: values.palace.password,
                },
              },
            });

            await createCompany({
              variables: {
                input: {
                  logo: values.company.logo,
                  name: values.company.name,
                },
              },
            });

            const { data: officeData } = await createOffice({
              variables: {
                input: {
                  address: values.company.address,
                },
              },
            });

            if (officeData?.createOffice.id) {
              localStorage.setItem("office", officeData?.createOffice.id);
              currentOfficeVar(officeData?.createOffice.id);

              await createPalace({
                variables: {
                  input: {
                    ...values.palace,
                    officeId: officeData?.createOffice.id,
                  },
                },
              });
            }

            client.refetchQueries({
              include: "all",
            });

            //TODO: change
            // await loadAppData?.();

            history.push("/dashboard");
          } catch (e) {
            console.error(e);
          }
        }}
      />
    </div>
  );
};

export default CompanySetup;
