import * as React from "react";
import Modal from "ui/Modal";
import { ModalProps } from "ui/Modal/Modal";
import AddOffice from "c/AddOffice/AddOffice";
import {
  useCreateOfficeMutation,
  useCreatePalaceMutation,
} from "generated/graphql";
import { useApolloClient } from "@apollo/client";

type AddOfficeModalProps = ModalProps;

const AddOfficeModal: React.FC<AddOfficeModalProps> = ({ onClose }) => {
  const client = useApolloClient();

  const [createPalace] = useCreatePalaceMutation();
  const [createOffice] = useCreateOfficeMutation();

  return (
    <Modal onClose={onClose}>
      <div className="max-w-md w-screen">
        <div className="title">Add Office</div>
        <AddOffice
          sumbitButtonLabel="Add Office"
          onSubmit={async (values) => {
            const { data: createOfficeData } = await createOffice({
              variables: {
                input: {
                  address: values.company.address,
                },
              },
            });

            if (!createOfficeData) {
              throw new Error(`Error occured while creating office`);
            }

            const { createOffice: office } = createOfficeData;

            await createPalace({
              variables: {
                input: {
                  ...values.palace,
                  officeId: office.id,
                },
              },
            });
            client.refetchQueries({
              include: ["Offices"],
            });

            onClose();
          }}
        />
      </div>
    </Modal>
  );
};

export default AddOfficeModal;
