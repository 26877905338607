export const formatPrice = (
  price: string | number,
  includeDecimal = false
): string => {
  const options: Intl.NumberFormatOptions = {};
  if (includeDecimal) {
    options.maximumFractionDigits = 2;
    options.minimumFractionDigits = 2;
  }

  if (Number.isNaN(Number(price))) return "-";
  return `$${Number(price).toLocaleString("en", options)}`;
};
