import Select from "c/Select";
import Collapse, { Panel } from "rc-collapse";
import * as React from "react";
import { useFormik } from "formik";
import Input from "c/Input";
import UploadFile from "ui/UploadFile";
import Button from "ui/Button";

interface IAddOfficeFormValues {
  integration: string | null;
  palace: {
    username: string;
    password: string;
    version: string;
  };
  company: {
    name: string;
    address: string;
    logo: File | null;
  };
}

interface AddOfficeProps {
  onSubmit: (values: IAddOfficeFormValues) => void;
  sumbitButtonLabel?: string;
}

const integrationOptions = [
  {
    label: "Palace",
    value: "palace",
  },
];

const AddOffice: React.FC<AddOfficeProps> = ({
  onSubmit,
  sumbitButtonLabel,
}) => {
  const [activePanelKey, setActivePanelKey] = React.useState("0");

  const { values, handleChange, setFieldValue, handleSubmit, isSubmitting } =
    useFormik<IAddOfficeFormValues>({
      initialValues: {
        integration: null,
        palace: {
          username: "",
          password: "",
          version: "",
        },
        company: {
          name: "",
          address: "",
          logo: null,
        },
      },
      onSubmit: async (submitValues) => {
        await onSubmit(submitValues);
      },
    });

  const selectedIntegration = integrationOptions.find(
    (i) => i.value === values.integration
  );

  const isStepValid = (step: string) => {
    switch (step) {
      case "0":
        return !!values.integration;
      case "1":
        return !!values.palace.username && !!values.palace.password;
      case "2":
      default:
        return (
          !!values.company.name &&
          !!values.company.address &&
          !!values.company.logo
        );
    }
  };

  return (
    <>
      <Collapse
        activeKey={activePanelKey}
        accordion
        className="w-full"
        onChange={(activePanelKey) => {
          const key = activePanelKey as string;

          // need to get integraion type before setting the integration fields
          if (values.integration === null && key === "1") return;

          setActivePanelKey(key);
        }}
      >
        <Panel panelKey="0" header="Step One" headerClass="my-header-class">
          <div className="flex items-center text-sm">
            <div className="mr-2 whitespace-nowrap">Integrate with: </div>
            <Select
              placeholder="Select one"
              options={integrationOptions}
              width="100%"
              onChange={(value) => {
                setFieldValue("integration", value);
                setActivePanelKey("1");
              }}
              value={selectedIntegration}
            />
          </div>
        </Panel>
        <Panel panelKey="1" header="Step Two">
          <div>
            <Input
              onChange={handleChange}
              value={values.palace.username}
              name="palace.username"
              containerClassName="mb-4"
              placeholder="Username*"
            />
            <Input
              type="password"
              onChange={handleChange}
              value={values.palace.password}
              name="palace.password"
              containerClassName="mb-4"
              placeholder="Password*"
            />
          </div>
        </Panel>
        <Panel panelKey="2" header="Step Three">
          <div>
            <Input
              onChange={handleChange}
              value={values.company.name}
              name="company.name"
              containerClassName="mb-4"
              placeholder="Company Name"
            />
            <Input
              onChange={handleChange}
              value={values.company.address}
              name="company.address"
              containerClassName="mb-4"
              placeholder="Company Address"
            />
            <UploadFile
              className="bg-gray-100"
              accept="image/*"
              files={values.company.logo ? [values.company.logo] : []}
              onDrop={(files) => {
                setFieldValue("company.logo", files[0]);
              }}
            />
          </div>
        </Panel>
      </Collapse>
      <Button
        disabled={!isStepValid(activePanelKey) || isSubmitting}
        onClick={() => {
          if (activePanelKey === "2") {
            // TODO: full check
            handleSubmit();
            return;
          }
          setActivePanelKey((key) => (+key + 1).toString());
        }}
        label={activePanelKey === "2" ? sumbitButtonLabel || "Sign up" : "Next"}
        className="px-8 mx-auto mt-4"
        type="submit"
      />
    </>
  );
};

export default AddOffice;
