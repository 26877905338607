import ErrorsModal from "c/ErrorsModal";
import React, { Component } from "react";

interface RuntimeErrorBoundaryProps {
  sendError: (error: string) => void;
}

interface RuntimeErrorBoundaryState {
  hasError: boolean;
}

class RuntimeErrorBoundary extends Component<
  RuntimeErrorBoundaryProps,
  RuntimeErrorBoundaryState
> {
  state: RuntimeErrorBoundaryState = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch = (error: Error, errorInfo: React.ErrorInfo) => {
    const { sendError } = this.props;

    console.error({ error, errorInfo });
    sendError(`${error.toString()} ${JSON.stringify(errorInfo)}`);
  };

  public render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <ErrorsModal
          errors={["Something went wrong."]}
          onClose={() => {
            this.setState({
              hasError: false,
            });
            window.location.reload();
          }}
        />
      );
    }

    return children;
  }
}

export default RuntimeErrorBoundary;
