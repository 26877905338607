import { Property, usePropertiesQuery } from "generated/graphql";
import * as React from "react";
import { getPropertyAddress } from "u/getPropertyAddress";
import Modal, { ModalProps } from "ui/Modal/Modal";

interface SelectPropertyModalProps extends ModalProps {
  onSelect: (property: Property) => void;
}

const SelectPropertyModal: React.FC<SelectPropertyModalProps> = ({
  onClose,
  onSelect,
}) => {
  const { data } = usePropertiesQuery();
  const properties = data?.properties as Property[];

  return (
    <Modal {...{ onClose }}>
      <div className="max-h-full overflow-hidden flex flex-col">
        <div className="text-3xl text-center  font-semibold mb-6">
          Select Property
        </div>
        <div className="overflow-y flex-auto min-h-full overflow-y-auto">
          {properties?.map((p) => {
            return (
              <div
                className="cursor-pointer rounded-2xl border p-4 text-gray-700 font-semibold hover:bg-gray-600 hover:text-white"
                onClick={() => {
                  onSelect(p);
                }}
                key={p.id}
              >
                {getPropertyAddress(p)}
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export default SelectPropertyModal;
