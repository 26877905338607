import * as React from "react";
import UploadFile from "ui/UploadFile";
import Inspections from "c/Inspections";
import WorkOrders from "c/WorkOrders";
import Button from "ui/Button";
import PropertyDetails from "c/PropertyDetails";
import StatisticsCard from "c/StatisticsCard";
import Spinner from "ui/Spinner";
import PropertiesMap from "./PropertiesMap";
import SelectPropertyModal from "./SelectPropertyModal";
import EstimatedValuation from "./EstimatedValuation";
import VacancyRate from "./VacancyRate";
import {
  useAttachFilesToPropertyMutation,
  useBalanceHistoryQuery,
  useLandlordHistoryQuery,
  useOfficeQuery,
  usePropertyHistoryQuery,
  UserRole,
  useUserQuery,
} from "generated/graphql";
import { useReactiveVar } from "@apollo/client";
import { currentOfficeVar } from "index";

type DashboardProps = {};

const Dashboard: React.FC<DashboardProps> = () => {
  const currentOfficeId = useReactiveVar(currentOfficeVar);
  const { data: userData, loading: userLoading } = useUserQuery();
  // const { currentOffice } = useCompany();
  const { data: officeData, loading: officeLoading } = useOfficeQuery({
    skip: !currentOfficeId,
  });

  const [propertyAttachments, setPropertyAttachments] = React.useState<File[]>(
    []
  );

  const [attachFilesToProperty] = useAttachFilesToPropertyMutation();
  const { data: landlordData, loading: landlordLoading } =
    useLandlordHistoryQuery();

  const { data: propertyData, loading: propertyLoading } =
    usePropertyHistoryQuery();

  const { data: balanceData, loading: balanceLoading } =
    useBalanceHistoryQuery();

  const currentUser = userData?.user;
  const currentOffice = officeData?.office;

  if (officeLoading || userLoading || !currentUser || !currentOffice)
    return <Spinner />;

  let content;
  if (currentUser.role === UserRole.Landlord) {
    content = (
      <>
        <div className="col-span-2 -mb-6 text-3xl font-semibold">
          Work Orders
        </div>
        <div className="col-span-2 -mb-6 text-3xl font-semibold">
          Inspections
        </div>
        <WorkOrders className="h-60" />
        <Inspections className="h-60" />
        <div className="-my-6 text-3xl font-semibold col-span-full">
          Statistics
        </div>
        <UploadFile
          className="flex-1"
          onDrop={(files) => {
            setPropertyAttachments(files);
          }}
          files={propertyAttachments}
        />
        <VacancyRate />
        <StatisticsCard
          dataType="price"
          data={balanceData?.balanceHistory ?? []}
          loading={balanceLoading}
          helpText="Historical Balance"
          title="Balance"
          lineColor="#B58FFF"
          pointColor="#7464FF"
        />
        <StatisticsCard
          data={propertyData?.propertyHistory ?? []}
          loading={propertyLoading}
          helpText="Historical Total Number of Properties"
          title="Total Managed Properties"
          lineColor="#8AF8F9"
          pointColor="#8AF8F9"
        />
      </>
    );
  }
  if ([UserRole.Admin, UserRole.PropertyManager].includes(currentUser.role)) {
    content = (
      <>
        <StatisticsCard
          dataType="price"
          data={balanceData?.balanceHistory ?? []}
          loading={balanceLoading}
          helpText="Historical Balance"
          title="Balance"
          lineColor="#B58FFF"
          pointColor="#7464FF"
        />
        <StatisticsCard
          data={landlordData?.landlordHistory ?? []}
          loading={landlordLoading}
          helpText="Historical Total Number of Landlords"
          title="Total Number of Landlords"
          lineColor="#8AF8F9"
          pointColor="#8AF8F9"
        />
        <StatisticsCard
          data={propertyData?.propertyHistory ?? []}
          loading={propertyLoading}
          helpText="Historical Total Number of Properties"
          title="Total Managed Properties"
          lineColor="#FF84A1"
          pointColor="#FF4F78"
        />
        <VacancyRate />
        <div className="col-span-3 ">
          <div className="mb-6 text-3xl font-semibold">Inspections</div>
          <Inspections className="h-60" />
        </div>
        <div className="flex flex-col justify-center">
          <EstimatedValuation />
          <UploadFile
            className="flex-1"
            onDrop={(files) => {
              setPropertyAttachments(files);
            }}
            files={propertyAttachments}
          />
        </div>
        <PropertiesMap />
      </>
    );
  }

  if (currentUser.role === UserRole.Tenant) {
    if (!currentUser.tenant?.property?.id) {
      // TODO: test
      return <div>No Property was found</div>;
    }

    content = (
      <PropertyDetails
        propertyId={currentUser.tenant?.property?.id}
        className="col-span-4"
      />
    );
  }

  // TODO: probably do it as a HOC for all main pages
  if (!currentOffice?.palace?.synchronizedAt) {
    return (
      <div className="flex flex-col items-center justify-center flex-1 text-center">
        <div className="text-3xl font-bold leading-10">
          The monkeys are bringing in all of your data from palace,
          <br /> go have a cuppa and come back in 15
        </div>
        <Button
          className="mt-4"
          color="gray"
          label="I've had a cuppa, show me my data"
          onClick={() => window.location.reload()}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col flex-1 overflow-x-hidden">
      {propertyAttachments.length > 0 && (
        <SelectPropertyModal
          onClose={() => setPropertyAttachments([])}
          onSelect={async (property) => {
            await attachFilesToProperty({
              variables: {
                input: {
                  id: property.id,
                  documents: propertyAttachments,
                },
              },
            });

            setPropertyAttachments([]);
          }}
        />
      )}
      {currentUser.role !== UserRole.Landlord && (
        <div className="mb-2 text-3xl font-semibold">Statistics</div>
      )}
      <div className="grid max-h-full grid-cols-4 gap-12 py-4 pr-4 overflow-x-hidden auto-rows-min ">
        {content}
      </div>
    </div>
  );
};

export default Dashboard;
