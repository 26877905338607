import { useChatsQuery, useUserQuery, useUsersQuery } from "generated/graphql";
import * as React from "react";
import Modal from "ui/Modal";

type AddChatModalProps = {
  onClose: () => void;
  onSelect: (id: string) => void;
};

const AddChatModal: React.FC<AddChatModalProps> = ({ onClose, onSelect }) => {
  const { data } = useChatsQuery();

  const { data: userData } = useUserQuery();
  const currentUser = userData?.user;

  const { data: usersData } = useUsersQuery();
  const users = usersData?.users.users;

  return (
    <Modal onClose={onClose}>
      <div className="max-h-full overflow-hidden flex flex-col">
        <div className="text-3xl text-center  font-semibold mb-6">
          Select User
        </div>
        <div className="overflow-y flex-auto min-h-full overflow-y-auto">
          {users
            ?.filter((u) => {
              if (u.id === currentUser?.id) return false;
              if (!u.isInvited) return false;
              return !data?.chats.find((chat: any) =>
                chat.users.find((user: any) => u.id === user.id)
              );
            })
            .map(({ name, id }) => (
              <div
                className="cursor-pointer rounded-2xl border p-4 text-gray-700 font-semibold hover:bg-gray-600 hover:text-white"
                key={id}
                onClick={async () => {
                  await onSelect(id);
                  onClose();
                }}
              >
                {name}
              </div>
            ))}
        </div>
      </div>
    </Modal>
  );
};

export default AddChatModal;
