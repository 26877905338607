import * as React from "react";
import classNames from "classnames/bind";
import styles from "./ArcProgressBar.module.scss";

const cx = classNames.bind(styles);

type ArcProgressBarProps = {
  className?: string;
  percent: number;
};

const ArcProgressBar: React.FC<ArcProgressBarProps> = ({
  className,
  percent,
}) => {
  const formattedPercent = percent.toFixed(2);
  const arcRadius = 45;
  const strokeDashoffset = arcRadius * 3.142 * (1 - percent / 100);
  return (
    <svg
      className={cx(styles.Arc, className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 50"
    >
      <path
        className={styles.Outline}
        d={`M5,50
           A${arcRadius},${arcRadius} 180 1,1 95,50`}
      />
      <path
        className={styles.Progress}
        style={{
          strokeDashoffset,
        }}
        d={`M5,50
           A${arcRadius},${arcRadius} 180 1,1 95,50`}
      />
      <text x="50" y="40" textAnchor="middle">
        {formattedPercent}%
      </text>
    </svg>
  );
};

export default ArcProgressBar;
