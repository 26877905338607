import * as React from "react";
import classNames from "classnames/bind";
import styles from "./Message.module.scss";
import { Message as IMessage, useUserQuery } from "generated/graphql";

const cx = classNames.bind(styles);

type MessageProps = {
  message: Pick<IMessage, "message" | "user" | "id">;
  isLastMessage: boolean;
  isFirstMessage: boolean;
};

const Message: React.FC<MessageProps> = ({
  message,
  isLastMessage,
  isFirstMessage,
}) => {
  const { data: userData } = useUserQuery();
  const currentUser = userData?.user;

  const messageRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (isLastMessage) {
      const scrollToMessage = setInterval(() => {
        if (messageRef.current) {
          messageRef.current.scrollIntoView({
            behavior: "auto",
            block: "end",
          });
          clearInterval(scrollToMessage);
        }
      }, 100);
    }
  }, [isLastMessage]);

  const isCurrentUser = currentUser?.id === message.user?.id;

  return (
    <div
      ref={messageRef}
      className={cx(" my-1 w-2/5  mr-4  flex ", {
        "mt-auto": isFirstMessage,
        "ml-auto justify-end": isCurrentUser,
        "justify-start": !isCurrentUser,
        [styles.LastMessage]: isLastMessage,
      })}
    >
      <div
        className={cx("break-words border-border-gray rounded-2xl py-2 px-4", {
          "bg-light-purple  text-white": isCurrentUser,
          "bg-gray-300  text-black": !isCurrentUser,
        })}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: message.message,
        }}
      />
    </div>
  );
};

export default Message;
