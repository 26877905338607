import * as React from "react";
import { htmlToText } from "html-to-text";
import { GrAttachment } from "react-icons/gr";
import ContentEditable from "react-contenteditable";
import { IoMdSend } from "react-icons/io";

type TextEditorProps = {
  addMessageToChat: (variables: {
    message: string;
    plainText: string;
  }) => Promise<void>;
};

const TextEditor: React.FC<TextEditorProps> = ({ addMessageToChat }) => {
  const currentMessageRef = React.useRef<string>("");
  const [, updateTextEditor] = React.useState<boolean>(false);

  const onAddMessage = async () => {
    const message = `<div>${currentMessageRef.current}</div>`;
    const plainText = htmlToText(message);

    if (!plainText.trim()) return;

    await addMessageToChat({
      plainText,
      message,
    });
    currentMessageRef.current = "";
    updateTextEditor((v) => !v);
  };

  const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = async (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      await onAddMessage();
    }
  };

  return (
    <div className="flex items-center mt-auto pt-6 mb-2 self-stretch mr-10">
      <GrAttachment size={25} className="cursor-pointer" />
      <div className="bg-dark-gray flex-1 mx-6 rounded-2xl">
        <ContentEditable
          key={currentMessageRef.current}
          html={currentMessageRef.current}
          className="max-h-24 overflow-y-auto py-2 px-4 outline-none"
          onChange={(e) => {
            currentMessageRef.current = e.target.value;
          }}
          onKeyDown={onKeyDown}
        />
      </div>
      <IoMdSend size={25} className="cursor-pointer" onClick={onAddMessage} />
      {/* <Editor
        apiKey="uxla6a6faiwo7cy86m2jk45mvrozjxtc2wakyql22urydiud"
        initialValue=""
        init={{
          menubar: false,
          plugins: "autoresize link lists emoticons",
          toolbar:
            "bold italic underline strikethrough  | numlist bullist | link blockquote emoticons",

          autoresize_bottom_margin: 0,
          toolbar_location: "bottom",
          statusbar: false,
          width: "100%",
          height: "6rem",
          contextmenu: false,
          setup: (editor: Editor["editor"]) => setEditor(editor),
        }}
        onEditorChange={(content) => setCurrentMessage(content)}
        value={currentMessage}
      />
      <div
        className="bg-blue-300 self-start ml-4 p-2 rounded-full cursor-pointer"
        onClick={() => {
          const plainText = editor?.getContent({ format: "text" }).trim();
          const message = editor?.getContent();
          if (!editor || !plainText || !message) {
            return;
          }

          addMessageToChat({
            message,
            plainText,
          });

          editor.resetContent();
        }}
      >
        <IoPaperPlaneOutline size={30} className="text-white" />
      </div> */}
    </div>
  );
};

export default TextEditor;
