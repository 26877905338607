import * as React from "react";
import ArcProgressBar from "ui/ArcProgressBar";
import HelpButton from "ui/HelpButton";
import Spinner from "ui/Spinner";
import classNames from "classnames/bind";
import styles from "./VacancyRate.module.scss";
import { usePropertiesQuery } from "generated/graphql";

const cx = classNames.bind(styles);

type VacancyRateProps = {};

const VacancyRate: React.FC<VacancyRateProps> = () => {
  const { data, loading } = usePropertiesQuery();
  const properties = data?.properties;
  const propertyCount = properties?.length ?? "-";
  const vacantPropertyCount =
    properties?.filter((p) => p.availableDate).length ?? "-";

  return (
    <div
      className={cx(
        "w-full items-center flex flex-col card hover:shadow-2xl relative"
      )}
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="font-semibold mb-4 text-2xl flex items-center text-center">
            Vacancy Rate
          </div>
          <HelpButton
            className=""
            text="The vacancy rate is the percentage of all available units in a rental property, such as a hotel or apartment complex, that are vacant or unoccupied at a particular time."
          />
          <ArcProgressBar
            percent={
              typeof vacantPropertyCount === "number" &&
              typeof propertyCount === "number"
                ? (vacantPropertyCount / propertyCount) * 100
                : 0
            }
          />
          <div className="mt-2 w-full">
            <div className="flex justify-between">
              <div>Total Properties</div>
              <b>{propertyCount}</b>
            </div>
            <div className="flex justify-between">
              <div>Total Vacant Properties</div>
              <b>{vacantPropertyCount}</b>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default VacancyRate;
