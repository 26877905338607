import * as React from "react";
import Input from "c/Input";
import Select from "c/Select";
import Button from "ui/Button";
import Modal from "ui/Modal";
import Spinner from "ui/Spinner";
import { getPropertyAddress } from "u/getPropertyAddress";
import Datetime from "react-datetime";
import { useFormik } from "formik";
import IssueForm from "c/AddOffice/IssueForm";
import { IIssueForm } from "c/AddOffice/IssueForm/IssueForm";
import "react-datetime/css/react-datetime.css";
import FileList from "c/FileList";
import { IInspection, ISelectOption } from "interfaces";
import {
  Attachment,
  Inspection,
  InspectionIssue,
  useAddInspectionMutation,
  usePropertiesQuery,
  UserRole,
  useUpdateInspectionMutation,
  useUsersQuery,
} from "generated/graphql";
import { Maybe } from "graphql/jsutils/Maybe";

const inspectionTypeOptions = [
  {
    label: "Pre Tenancy Inspection",
    value: "Pre Tenancy Inspection",
  },
  {
    label: "Routine Inspection",
    value: "Routine Inspection",
  },
  {
    label: "End of Tenancy Inspection",
    value: "End of Tenancy Inspection",
  },
];

interface IFormInspection
  extends Omit<
    IInspection,
    "propertyManager" | "property" | "date" | "id" | "attachments" | "issues"
  > {
  propertyManager?: string;
  property?: string;
  date: Date;
  attachments: (File | Maybe<Attachment>)[];
  issues: InspectionIssue[];
}

const initialInspectionForm: IFormInspection = {
  propertyManager: undefined,
  property: undefined,
  issues: [],
  date: new Date(),
  type: "",
  description: "",
  youtubeUrl: "",
  matterportUrl: "",
  attachments: [],
};

type AddEditInspectionProps = {
  onClose: () => void;
  inspection?: Inspection;
};

const isAttachment = (file: File | Attachment) => {
  return typeof (file as Attachment).url === "string";
};

const AddEditInspection: React.FC<AddEditInspectionProps> = ({
  onClose,
  inspection,
}) => {
  const issueFormRef = React.useRef<React.ElementRef<typeof IssueForm>>(null);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [isIssueFormOpen, setIsIssueFormOpen] = React.useState(false);

  const [addInspection] = useAddInspectionMutation();
  const [updateInspection] = useUpdateInspectionMutation();

  // const { data: usersData, loading: usersLoading } = useUsersQuery({
  //   variables: {
  //     input: {
  //       role: UserRole.PropertyManager,
  //     },
  //   },
  // });

  const { data: usersData, loading: usersLoading } = useUsersQuery({
    variables: {
      role: UserRole.PropertyManager,
    },
  });

  const { data: propertiesData, loading: propertiesLoading } =
    usePropertiesQuery();

  const { values, handleSubmit, handleChange, setFieldValue, isSubmitting } =
    useFormik<IFormInspection>({
      initialValues: inspection
        ? {
            ...inspection,
            attachments:
              inspection?.attachments?.map(({ name, url }) => ({
                name,
                url,
              })) ?? [],
            youtubeUrl: inspection.youtubeUrl ?? "",
            matterportUrl: inspection.matterportUrl ?? "",
            property: inspection.property.id,
            propertyManager: inspection.propertyManager?.id,
            date: new Date(inspection.date),
          }
        : initialInspectionForm,
      onSubmit: async (values) => {
        try {
          const requiredFields: (keyof IFormInspection)[] = [
            "propertyManager",
            "property",
            "date",
            "type",
          ];
          // TODO: do normal validation
          // second page is not required, so we may not even need this here
          if (requiredFields.some((k) => !values[k])) {
            return;
          }
          if (inspection) {
            delete values.__typename;

            const attachments = values.attachments.map((a: any) => {
              // eslint-disable-next-line no-param-reassign
              delete a.__typename;
              return a;
            });

            const issues = values.issues.map((i) => {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              const { __typename, ...newIssue } = i;

              newIssue.attachments = newIssue.attachments.map((a) => {
                if (isAttachment(a as Attachment)) {
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  const { __typename, ...attachment } = a;
                  return attachment;
                }
                return a;
              });

              return newIssue;
            });

            await updateInspection({
              variables: {
                input: {
                  id: inspection.id,
                  ...values,
                  issues: issues.map((issue) => ({
                    ...issue,
                    attachments: issue.attachments.filter(
                      (a) => !isAttachment(a)
                    ),
                    uploadedAttachments: issue.attachments.filter((a) =>
                      isAttachment(a)
                    ),
                  })),
                  attachments: attachments.filter((a) => !isAttachment(a)),
                  uploadedAttachments: attachments.filter((a) =>
                    isAttachment(a)
                  ),
                },
              },
              refetchQueries: ["inspections"],
            });
          } else {
            await addInspection({
              variables: {
                input: values,
              },
              refetchQueries: ["inspections"],
            });
          }
          onClose();
        } catch (e) {
          console.error(e);
        }
      },
    });

  React.useEffect(() => {
    if (values.propertyManager || usersData?.users.users.length !== 1) return;
    setFieldValue(
      "propertyManager",
      usersData?.users.users?.[0]?.propertyManager?.id ?? null
    );
  }, [values.propertyManager, usersData?.users, setFieldValue]);
  if (usersLoading || propertiesLoading || isSubmitting) return <Spinner />;
  const inspectedByOptions =
    usersData?.users.users.map(({ email, name, propertyManager }) => ({
      label: name || email,
      value: propertyManager?.id,
    })) ?? [];
  const inspectedByValue =
    values.propertyManager &&
    inspectedByOptions.find((u) => u.value === values.propertyManager);
  const addressOptions = propertiesData?.properties.map((p) => ({
    label: getPropertyAddress(p),
    value: p.id,
  }));
  const addressValue =
    values.property && addressOptions?.find((a) => a.value === values.property);

  let content;
  if (currentPage === 0) {
    content = (
      <>
        <div className="flex items-center my-4">
          <div className="w-40 font-bold">Inspected By</div>
          <Select
            onChange={(value) => setFieldValue("propertyManager", value?.value)}
            value={inspectedByValue as ISelectOption | null}
            options={inspectedByOptions}
            width={300}
          />
        </div>
        <div className="flex  items-center my-4">
          <div className="w-40 font-bold">Date and Time</div>
          <Datetime
            dateFormat="DD/MM/YYYY"
            className="w-80"
            value={values.date}
            onChange={(momentDate) => {
              if (typeof momentDate !== "string") {
                setFieldValue("date", momentDate.toDate());
              }
            }}
          />
        </div>
        <div className="flex  items-center my-4">
          <div className="w-40 font-bold">Inspection Type</div>
          <Select
            containerClassName="w-80"
            options={inspectionTypeOptions}
            onChange={(value) => setFieldValue("type", value?.value)}
            value={inspectionTypeOptions.find((i) => i.value === values.type)}
          />
        </div>
        <div className="flex  items-center my-4">
          <div className="w-40 font-bold">Address</div>
          <Select
            value={addressValue || null}
            options={addressOptions}
            onChange={(value) => setFieldValue("property", value?.value)}
            width={300}
          />
        </div>

        <div className="flex  items-center my-4">
          <div className="w-40 font-bold">Issues</div>
          {!isIssueFormOpen && (
            <div
              className="underline cursor-pointer"
              onClick={() => setIsIssueFormOpen(true)}
            >
              Add an issue
            </div>
          )}
        </div>
        {values.issues.map(({ description, attachments }, i) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i} className="flex  items-center my-2">
              <div className="w-40">{description}</div>
              <div>{attachments.length} attachments</div>
            </div>
          );
        })}
        {isIssueFormOpen && (
          <IssueForm
            ref={issueFormRef}
            onAdd={(issue: IIssueForm) => {
              setFieldValue("issues", [...values.issues, issue]);
              setIsIssueFormOpen(false);
            }}
          />
        )}

        <div className="flex justify-center mb-4 mt-8">
          <Button
            onClick={onClose}
            className="mx-2 "
            color="gray"
            label="Cancel"
          />
          <Button
            className="mx-2"
            label="Next"
            onClick={() => {
              const { propertyManager, type, property } = values;
              // TODO: do normal validation
              if (!propertyManager || !type || !property) {
                return;
              }

              // ref with form data
              const issue = issueFormRef?.current?.getValues();
              if (
                issue?.description ||
                (issue?.attachments && issue?.attachments.length > 0)
              ) {
                setFieldValue("issues", [...values.issues, issue]);
              }
              setIsIssueFormOpen(false);
              setCurrentPage(1);
            }}
          />
        </div>
      </>
    );
  }

  if (currentPage === 1) {
    content = (
      <>
        <div>
          <div className="font-bold mb-4">Inspection info</div>
          <textarea
            value={values.description}
            name="description"
            onChange={handleChange}
            cols={4}
            className="h-32 p-2 w-full border border-gray-300 rounded-lg outline-none"
          />
        </div>
        <div className="flex  items-center my-4">
          <div className="w-40 font-bold">YouTube URL</div>
          <Input
            containerClassName="w-80"
            onChange={handleChange}
            value={values.youtubeUrl}
            name="youtubeUrl"
          />
        </div>
        <div className="flex  items-center my-4">
          <div className="w-40 font-bold">Matterport URL</div>
          <Input
            containerClassName="w-80"
            onChange={handleChange}
            value={values.matterportUrl}
            name="matterportUrl"
          />
        </div>
        <FileList
          files={values.attachments}
          onDrop={(files) =>
            setFieldValue("attachments", [...files, ...values.attachments])
          }
          onDelete={(files) => setFieldValue("attachments", files)}
        />
        <div className="flex justify-center  mb-4 mt-8 ">
          <Button
            className="mx-2"
            label="Back"
            onClick={() => setCurrentPage(0)}
          />
          <Button type="submit" className="mx-2 " label="Save" />
        </div>
      </>
    );
  }

  return (
    <Modal onClose={onClose} className="overflow-y-auto mx-2 pr-2 ">
      <form onSubmit={handleSubmit} className="w-120">
        <h3 className="text-3xl text-center  font-semibold mb-6">
          {inspection ? "Edit" : "Add"} Inspection
        </h3>
        {content}
      </form>
    </Modal>
  );
};

export default AddEditInspection;
