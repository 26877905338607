import * as React from "react";
import classNames from "classnames/bind";
import { useParams } from "react-router-dom";
import PropertyDetails from "c/PropertyDetails";
import styles from "./Property.module.scss";

const cx = classNames.bind(styles);

type PropertyProps = {};

type PropertyParams = {
  id: string;
};

// TODO: status floorarea or landarea, mb add carParks
// TODO: what are start/end dates next to balance???

const Property: React.FC<PropertyProps> = () => {
  const { id } = useParams<PropertyParams>();

  return <PropertyDetails propertyId={id} className={cx("")} />;
};

export default Property;
