import * as React from "react";
import Swiper from "react-id-swiper";
import { FiFileText } from "react-icons/fi";
import { IoPersonCircleSharp } from "react-icons/io5";
import WorkOrders from "c/WorkOrders";
import Inspections from "c/Inspections";
import ProgressBar from "ui/ProgressBar";
import Spinner from "ui/Spinner";
import { formatPrice } from "u/formatPrice";
import classNames from "classnames/bind";
import { getPropertyAddress } from "u/getPropertyAddress";
import Button from "ui/Button";
import { ReactComponent as ArrowIcon } from "i/4arrows-icon.svg";
import { ReactComponent as BedIcon } from "i/bed-icon.svg";
import { ReactComponent as BathIcon } from "i/bathroom-icon.svg";
import { format } from "date-fns";
import styles from "./PropertyDetails.module.scss";
import {
  Tenant,
  useAttachFilesToPropertyMutation,
  usePropertyQuery,
} from "generated/graphql";

const cx = classNames.bind(styles);
// TODO: change nav icons
// TODO: shall we use bond from ex tenancies, if no active? if so, which one to choose
// TODO: fade both & center

type PropertyDetailsProps = {
  propertyId: string;
  className?: string;
};

const PropertyDetails: React.FC<PropertyDetailsProps> = ({
  propertyId,
  className,
}) => {
  const fileUploadInput = React.useRef<HTMLInputElement>(null);
  const [attachFilesToProperty, { loading: isAttachingFiles }] =
    useAttachFilesToPropertyMutation();

  const { data, loading } = usePropertyQuery({
    variables: { id: propertyId },
  });

  if (!propertyId) throw new Error(`propertyId wasn't provided`);

  if (loading) return <Spinner />;

  if (!data) throw new Error(`Error occured while loading Property`);

  const { property } = data;

  if (!property)
    return (
      <div className="flex-1 text-2xl font-semibold text-center">
        404. Property not found
      </div>
    );
  const tenancies = property.tenancies.filter(({ dateEnded }) => !dateEnded);
  const tenancy = property.tenancies[0];

  const tenants = tenancies.reduce(
    (acc: Pick<Tenant, "fullName" | "id" | "email">[], t) => {
      return [...acc, ...t.tenants];
    },
    []
  );

  const cardClassNames = "bg-white rounded-xl px-6 py-8 mb-6";
  return (
    <div
      className={cx(
        "flex flex-1 max-h-full overflow-y-auto pr-4 overflow-x-hidden",
        className
      )}
    >
      <div className="flex-1 flex-shrink-0 min-w-0 pr-4">
        {property.images.length > 0 && (
          <Swiper
            slidesPerView="auto"
            pagination={{
              el: ".swiper-pagination",
              clickable: true,
            }}
            loop
            containerClass="h-1/2-full overflow-hidden relative w-full"
            slideClass="w-auto"
            spaceBetween={25}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
          >
            {property.images.map(({ url }) => (
              <img
                key={url}
                src={url}
                alt="address"
                className="w-auto h-1/2-full rounded-2xl"
              />
            ))}
          </Swiper>
        )}
        <div className="mt-8 mb-4 text-3xl font-semibold">
          {getPropertyAddress(property)}
        </div>
        <div className="flex justify-between">
          <div className="flex items-center leading-5">
            <IoPersonCircleSharp size={60} />
            <div className="ml-2 font-medium">
              <div className="text-black ">
                {property.propertyManager?.user?.name}
              </div>
              <a
                href={`mailto:${property.propertyManager?.user?.email}`}
                className="text-sm text-gray-400"
              >
                {property.propertyManager?.user?.email}
              </a>
            </div>
          </div>
        </div>
        <div className="flex my-6 text-sm font-medium text-black">
          <div className="flex items-center mr-6">
            <BedIcon className="mr-2 text-xl text-blue-600" />
            <div>{property.bedrooms} Bedrooms</div>
          </div>
          <div className="flex items-center mr-6">
            <BathIcon className="mr-2 text-xl text-blue-600" />
            <div>{property.bathrooms} Bathrooms</div>
          </div>
          <div className="flex items-center mr-6">
            <ArrowIcon className="mr-2 text-xl text-blue-600" />
            <div>{property.landArea || property.floorArea || "-"} sq</div>
          </div>
        </div>
        <pre className="mb-8 whitespace-pre-wrap">{property.description}</pre>
        <div className="mb-4">
          <div className="mb-4 text-lg font-semibold">Work Orders</div>
          <WorkOrders
            propertyId={propertyId}
            className="overflow-hidden max-h-60"
            after={tenancy?.dateStarted}
          />
        </div>
        <div className="mb-4">
          <div className="mb-4 text-lg font-semibold">Inspections</div>
          <Inspections
            showPropertyImage={false}
            propertyId={propertyId}
            className="overflow-hidden max-h-60"
          />
        </div>
      </div>
      <div className="flex-shrink-0 w-72">
        {property.landlord && (
          <div className={cardClassNames}>
            <div className="text-lg font-bold">Landlord</div>
            <div className="my-3 text-sm font-medium">
              <div className="text-black">
                {property.landlord?.user?.name ?? "-"}
              </div>
              <a
                href={`mailto:${property.landlord?.user?.email}`}
                className="text-gray-400"
              >
                {property.landlord?.user?.email ?? "-"}
              </a>
            </div>
          </div>
        )}

        <div className={cardClassNames}>
          <div className="pb-6 mb-6 border-b border-gray-300">
            <div className="font-bold text-center text-gray-400">
              Estimated iVal
            </div>
            <div className="my-4 text-2xl font-semibold text-center">$ -</div>
            <ProgressBar />
          </div>
          <div className="pb-6 mb-6 border-b border-gray-300">
            <div className="font-bold text-center text-gray-400">
              Property Balance
            </div>
            <div className="mt-4 text-2xl font-semibold text-center">
              {formatPrice(property.currentBalance)}
            </div>
          </div>
          <div>
            <div className="font-bold text-center text-gray-400">Bond</div>
            <div className="mt-4 text-2xl font-semibold text-center">
              {tenancies[0]?.bondRequired
                ? formatPrice(tenancies[0]?.bondRequired)
                : "-"}
            </div>
          </div>
        </div>
        <div className={cx(cardClassNames, "relative")}>
          <div className="text-lg font-bold">Documents</div>
          <div className="p-4 my-4 bg-gray-100 rounded-xl">
            <div className="h-20 overflow-y-auto text-sm font-semibold">
              {property.documents?.map((document) => (
                <a
                  key={document.url}
                  href={document.url}
                  className="flex items-center mb-2"
                >
                  <FiFileText className="flex-shrink-0 mr-2" size={20} />
                  <div className="text-xs line-clamp-1">
                    {`${document.name} ${String.fromCharCode(160)}`}
                  </div>
                </a>
              ))}
              {!property.documents?.length && (
                <div className="text-sm text-center text-very-dark-gray">
                  No Documents Found
                </div>
              )}
            </div>
          </div>
          {isAttachingFiles ? (
            <Spinner />
          ) : (
            <>
              <input
                type="file"
                multiple
                className="hidden"
                ref={fileUploadInput}
                onChange={async (e) => {
                  const files = Array.from(e.target.files as FileList);
                  await attachFilesToProperty({
                    variables: {
                      input: {
                        id: propertyId,
                        documents: files,
                      },
                    },
                  });
                }}
              />
              <Button
                label="Upload file"
                color="pink"
                type="submit"
                className="mx-auto mt-6"
                onClick={() => fileUploadInput.current?.click()}
              />
            </>
          )}
        </div>
        {tenants.length > 0 && (
          <div className={cx(cardClassNames, "text-sm")}>
            <div className="text-lg font-bold">Tenants</div>
            {tenants.map((tenant) => (
              <div key={tenant.id} className="my-3 font-medium">
                <div className="text-black">{tenant.fullName}</div>
                <a href={`mailto:${tenant.email}`} className="text-gray-400">
                  {tenant.email}
                </a>
              </div>
            ))}
          </div>
        )}
        {tenancy && (
          <div className={cx(cardClassNames, "text-sm")}>
            <div className="text-lg font-bold">Lease Summary</div>
            <div className="my-3">
              <div className="font-semibold">Start Date:</div>
              <div>{format(new Date(tenancy?.dateStarted), "dd/MM/yyyy")}</div>
            </div>
            {tenancy?.dateEnded && (
              <div className="my-3">
                <div className="font-semibold">End Date:</div>
                <div>{format(new Date(tenancy?.dateEnded), "dd/MM/yyyy")}</div>
              </div>
            )}
            <div className="my-3">
              <div className="font-semibold">Weekly Rent:</div>
              <div>${tenancy?.currentRent ?? "-"}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PropertyDetails;
