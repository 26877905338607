import { useApolloClient } from "@apollo/client";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as React from "react";
import Button from "ui/Button";
import Input from "c/Input";
import { UserRole, useSignUpMutation, useUserQuery } from "generated/graphql";

type SignUpProps = {};

const SignUp: React.FC<SignUpProps> = () => {
  const client = useApolloClient();
  const [signup] = useSignUpMutation();
  const history = useHistory();

  const { data } = useUserQuery();

  const currentUser = data?.user;

  React.useEffect(() => {
    if (currentUser) {
      history.push("/signup/company");
    }
  }, [currentUser, history]);

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: {
      email: "",
      name: "",
      password: "",
    },
    onSubmit: async (fields) => {
      try {
        const { data: signUpData } = await signup({
          variables: { input: { ...fields, role: UserRole.Admin } },
        });

        localStorage.setItem("token", signUpData?.signUp.token ?? "");
        client.refetchQueries({
          include: "all",
        });

        history.push("/signup/company");
      } catch (e) {
        console.error(e);
      }
    },
  });

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col items-center justify-center w-full max-w-md px-10 py-10 bg-white border rounded-2xl border-border-gray"
    >
      <h1 className="mb-6 text-3xl font-semibold">Create an Account</h1>
      <Input
        onChange={handleChange}
        value={values.name}
        name="name"
        containerClassName="mb-4 w-full"
        placeholder="Name"
      />
      <Input
        onChange={handleChange}
        value={values.email}
        name="email"
        containerClassName="mb-4 w-full"
        placeholder="Email"
      />
      <Input
        type="password"
        onChange={handleChange}
        value={values.password}
        name="password"
        containerClassName="mb-4 w-full"
        placeholder="Password"
      />
      <Button className="self-center px-8 my-4" label="Next" type="submit" />
    </form>
  );
};

export default SignUp;
