import * as React from "react";
import ReactPaginate from "react-paginate";
import { BiGlassesAlt } from "react-icons/bi";
import { FiEdit2 } from "react-icons/fi";
import { format } from "date-fns";
import { getPropertyAddress } from "u/getPropertyAddress";
import Button from "ui/Button";
import Spinner from "ui/Spinner";
import InspectionModal from "c/InspectionModal";
import AddEditInspection from "l/MainLayout/AddEditInspection";
import { Nullable } from "types";
import classNames from "classnames/bind";
import styles from "./Inspections.module.scss";
import {
  Inspection,
  useInspectionsQuery,
  UserRole,
  useUserQuery,
} from "generated/graphql";

const cx = classNames.bind(styles);

type InspectionsProps = {};

const Inspections: React.FC<InspectionsProps> = () => {
  const [openedInspectionId, setOpenedInspectionId] =
    React.useState<Nullable<string>>(null);
  const [editingInspectionId, setEditingInspectionId] =
    React.useState<Nullable<string>>(null);
  const [currentPage, setCurrentPage] = React.useState(1);

  const { data: userData } = useUserQuery();
  const currentUser = userData?.user;

  const { data, loading, refetch } = useInspectionsQuery({
    variables: {
      pagination: {
        limit: 20,
      },
    },
  });

  React.useEffect(() => {
    refetch({
      pagination: {
        limit: 20,
        page: currentPage,
      },
    });
  }, [currentPage, refetch]);

  if (loading) return <Spinner />;

  if (!data) throw new Error("Error occured");

  const { inspections, pagination } = data.inspections;

  const openedInspection =
    openedInspectionId && inspections.find((i) => i.id === openedInspectionId);

  const editingInspection =
    editingInspectionId &&
    inspections.find((i) => i.id === editingInspectionId);

  return (
    <div className="flex-1 flex-shrink-0 ">
      {openedInspectionId && (
        <InspectionModal
          onClose={() => setOpenedInspectionId(null)}
          inspection={openedInspection as Inspection}
        />
      )}
      {editingInspectionId && (
        <AddEditInspection
          onClose={() => setEditingInspectionId(null)}
          inspection={editingInspection as Inspection}
        />
      )}
      {inspections.length > 0 ? (
        <div className="flex flex-col h-full max-h-full">
          <div className="flex font-bold text-center pr-7">
            <div className="w-12" />
            <div className="flex-1 mr-2">Address</div>
            <div className="w-24 mr-2">Date</div>
            <div className="w-24 mr-2">Time</div>
            <div className="w-24 mr-2">Type</div>
            <div className="w-24 mr-2">Status</div>
            <div className="w-10" />
            <div className="w-24" />
          </div>
          <div className="pr-4 overflow-auto">
            {inspections.map(
              ({ date, type, id, issues, property, propertyManager }) => {
                const canEditInspection =
                  currentUser?.role === UserRole.Admin ||
                  (propertyManager?.id &&
                    currentUser?.role === UserRole.PropertyManager &&
                    currentUser?.propertyManager?.id === propertyManager.id);
                return (
                  <div
                    key={id}
                    className="flex items-center py-2 text-center transition-all duration-300 hover:bg-gray-200"
                  >
                    <div className="flex justify-center w-12">
                      <BiGlassesAlt size={25} className="text-gray-300" />
                    </div>
                    <div className="flex-1 mr-2">
                      {getPropertyAddress(property)}{" "}
                    </div>
                    <div className="w-24 mr-2">
                      {format(new Date(date), "dd/MM/yyyy")}
                    </div>
                    <div className="w-24 mr-2">
                      {" "}
                      {format(new Date(date), "hh:mm aa")}
                    </div>
                    <div className="w-24 mr-2">{type}</div>
                    <div className="w-24 mr-2">
                      {issues.length} issue{issues.length > 1 ? "s" : ""}
                    </div>
                    <div className={cx("w-10 justify-center")}>
                      <FiEdit2
                        className={cx("flex-shrink-0 cursor-pointer", {
                          hidden: !canEditInspection,
                        })}
                        onClick={() => setEditingInspectionId(id)}
                      />
                    </div>

                    <Button
                      label="View"
                      padding="small"
                      className="w-24"
                      onClick={() => {
                        setOpenedInspectionId(id);
                      }}
                    />
                  </div>
                );
              }
            )}
          </div>
          <ReactPaginate
            pageCount={pagination.totalPages}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            containerClassName="flex mx-auto mt-auto pt-4 select-none"
            activeClassName="font-bold"
            previousLabel=""
            nextLabel=""
            pageClassName="mx-1"
            breakClassName="mx-1"
            pageLinkClassName="outline-none"
            breakLinkClassName="outline-none"
            initialPage={0}
            onPageChange={({ selected }) => setCurrentPage(selected + 1)}
          />
        </div>
      ) : (
        <div className="flex items-center justify-center flex-1 col-span-2 text-3xl text-center">
          <div>No Inspections Yet</div>
        </div>
      )}
    </div>
  );
};

export default Inspections;
