import { User, UserRole } from "generated/graphql";
import { UserRoleFields } from "interfaces";

type RoleField = {
  role: UserRole;
  field: Exclude<UserRoleFields, "admin">;
};

const roleToFieldMap: RoleField[] = [
  {
    role: UserRole.Landlord,
    field: "landlord",
  },
  {
    role: UserRole.PropertyManager,
    field: "propertyManager",
  },
  {
    role: UserRole.Tenant,
    field: "tenant",
  },
];

export const getRoleFields = (
  user: Pick<User, "role" | "landlord" | "tenant" | "propertyManager">
): User["landlord"] | User["tenant"] | User["propertyManager"] | undefined => {
  if (user.role === UserRole.Admin) {
    return undefined;
  }

  const roleData = roleToFieldMap.find(({ role }) => role === user.role);

  if (!roleData) throw new Error(`Role wasn't found`);
  return user[roleData.field] as
    | User["landlord"]
    | User["tenant"]
    | User["propertyManager"]
    | undefined;
};
