import * as React from "react";
import { TiTick } from "react-icons/ti";
import * as Yup from "yup";
import Button from "ui/Button";
import Input from "c/Input";
import { useFormik } from "formik";
import ProfileHeader from "c/ProfileHeader";
import { getRoleFields } from "u/getRoleFields";
import Spinner from "ui/Spinner";
import Modal from "ui/Modal";
import Reference from "yup/lib/Reference";
import UploadNewPhoto from "./UploadNewPhoto";
import {
  User,
  useUpdateUserPasswordMutation,
  useUserQuery,
} from "generated/graphql";

type ProfileProps = {};

// TODO: test error messaging & style

const Profile: React.FC<ProfileProps> = () => {
  const [isChangingPassword, setIsChangingPassword] =
    React.useState<boolean>(false);
  const [isUploadNewPhotoOpen, setIsUploadNewPhotoOpen] =
    React.useState<boolean>(false);
  const [isPasswordChangedModalOpen, setIsPasswordChangedModalOpen] =
    React.useState<boolean>(false);

  const { data: userData } = useUserQuery();
  const currentUser = userData?.user;
  const [updateUserPassword] = useUpdateUserPasswordMutation();

  const { values, errors, touched, handleSubmit, handleChange, isSubmitting } =
    useFormik({
      initialValues: {
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      },
      validationSchema: {
        currentPassword: Yup.string().required(),
        newPassword: Yup.string().required(),
        confirmNewPassword: Yup.string()
          .oneOf([Yup.ref("newPassword")], "Password does not match")
          .required(),
      },
      onSubmit: async (
        { currentPassword, newPassword, confirmNewPassword },
        { resetForm }
      ) => {
        if (isChangingPassword && confirmNewPassword === newPassword) {
          await updateUserPassword({
            variables: {
              input: {
                currentPassword,
                newPassword,
              },
            },
          });
          setIsPasswordChangedModalOpen(true);
          setIsChangingPassword(false);
          resetForm();
        }
      },
    });

  if (!currentUser) return <Spinner />;

  const userFields = getRoleFields(currentUser as User);

  return (
    <div className="flex flex-col items-center justify-center flex-1 w-full h-full ">
      {isUploadNewPhotoOpen && (
        <UploadNewPhoto onClose={() => setIsUploadNewPhotoOpen(false)} />
      )}
      <div className="flex flex-col p-6 px-10 py-10 bg-white border rounded-3xl border-border-gray">
        <ProfileHeader
          name={currentUser.name}
          avatar={currentUser.avatar}
          balance={
            userFields && "currentBalance" in userFields
              ? userFields?.currentBalance ?? 0
              : 0
          }
          properties={userFields?.propertyCount ?? 0}
          onEditPhoto={() => setIsUploadNewPhotoOpen(true)}
        />
        <div className="flex items-center mb-8">
          <div className="mr-2 font-bold w-28">Email</div>
          <div>{currentUser.email}</div>
        </div>
        <div className="flex items-center">
          <div className="mr-2 font-bold w-28">Phone</div>
          <div>{currentUser.phone ?? "-"}</div>
        </div>
        <div
          className="my-4 text-sm text-gray-400 cursor-pointer hover:underline"
          onClick={() => setIsChangingPassword((v) => !v)}
        >
          {isChangingPassword ? "Cancel Change Password" : "Change Password"}
        </div>
        {isChangingPassword && (
          <>
            <div className="flex items-center mb-8">
              <div className="mr-2 font-bold w-28">Current Password</div>
              <Input
                type="password"
                value={values.currentPassword}
                name="currentPassword"
                onChange={handleChange}
                placeholder="Current Password"
              />
            </div>
            {errors.currentPassword && touched.currentPassword && (
              <div>{errors.currentPassword}</div>
            )}
            <div className="flex items-center mb-8">
              <div className="mr-2 font-bold w-28">New Password</div>
              <Input
                type="password"
                value={values.newPassword}
                name="newPassword"
                onChange={handleChange}
                placeholder="New Password"
              />
            </div>
            {errors.newPassword && touched.newPassword && (
              <div>{errors.newPassword}</div>
            )}
            <div className="flex items-center mb-8">
              <div className="mr-2 font-bold w-28">Confirm New Password</div>
              <Input
                type="password"
                value={values.confirmNewPassword}
                name="confirmNewPassword"
                onChange={handleChange}
                placeholder="Confirm New Password"
              />
            </div>
            {errors.confirmNewPassword && touched.confirmNewPassword && (
              <div>{errors.confirmNewPassword}</div>
            )}
            <Button
              disabled={isSubmitting}
              label="save"
              className="self-center"
              onClick={() => handleSubmit()}
            />
          </>
        )}
      </div>
      {isPasswordChangedModalOpen && (
        <Modal
          onClose={() => {
            setIsPasswordChangedModalOpen(false);
          }}
        >
          <div>
            <TiTick
              className="mx-auto mb-4 text-green-700 border border-green-700 rounded-full"
              size={100}
            />
            <div className="font-semibold">
              Password has changed successfully!
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Profile;
