import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import Inspections from "p/Inspections";
import WorkOrders from "p/WorkOrders";
import AuthLayout from "l/AuthLayout";
import MainLayout from "l/MainLayout";
import CompanySetup from "p/CompanySetup";
import Dashboard from "p/Dashboard";
import HelpCenter from "p/HelpCenter";
import Integrations from "p/Integrations";
import InviteSignUp from "p/InviteSignUp";
import Login from "p/Login";
import Profile from "p/Profile";
import Properties from "p/Properties";
import Property from "p/Property";
import RecoverAccount from "p/RecoverAccount";
import SignUp from "p/SignUp";
import Users from "p/Users";
import { ReactNode, useEffect } from "react";
import Chat from "p/Chat";
import ResetAccountPassword from "p/ResetAccountPassword";
import { useOfficesQuery, useUserQuery } from "generated/graphql";
import Spinner from "ui/Spinner";
import Company from "p/Company";
import { currentOfficeVar } from "index";
import { useApolloClient } from "@apollo/client";

const pages = [
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/properties",
    component: Properties,
  },
  {
    path: "/properties/:id",
    component: Property,
  },
  {
    path: "/inspections",
    component: Inspections,
  },
  {
    path: "/work-orders",
    component: WorkOrders,
  },
  {
    path: "/profile",
    component: Profile,
  },
  {
    path: "/users",
    component: Users,
  },
  {
    path: "/integrations",
    component: Integrations,
  },
  {
    path: "/help",
    component: HelpCenter,
  },
  {
    path: "/chat",
    component: Chat,
  },
  {
    path: "/company",
    component: Company,
  },
];

const authPages = [
  {
    path: "/recovery",
    component: RecoverAccount,
  },
  {
    path: "/recovery/:token",
    component: ResetAccountPassword,
  },
  {
    path: "/",
    component: Login,
  },
  {
    path: "/signup",
    component: SignUp,
  },
  {
    path: "/signup/company",
    component: CompanySetup,
  },
  {
    path: "/signup/invite/:token",
    component: InviteSignUp,
  },
];

const Routes = () => {
  const location = useLocation();
  const client = useApolloClient();
  const currentOfficeId = localStorage.getItem("office");

  const { data, loading } = useUserQuery();
  const { data: officesData } = useOfficesQuery();

  //TODO: need to do something if current office is different
  useEffect(() => {
    if (currentOfficeId || !officesData?.offices.length) return;

    localStorage.setItem("office", officesData?.offices[0]?.id);
    currentOfficeVar(officesData?.offices[0]?.id);
    client.refetchQueries({
      include: "all",
    });
  }, [currentOfficeId, officesData, client]);

  if (loading) return <Spinner />;

  if (!data?.user.isSignedUp) {
    // user went trough only first step of signup
    let routeRedirect: ReactNode;
    if (data?.user && !location.pathname.includes("/signup")) {
      routeRedirect = <Redirect to="/signup/company" />;
    }

    return (
      <Switch>
        {routeRedirect}
        {authPages.map(({ path, component: Component }) => {
          return (
            <Route key={path} exact path={path}>
              <AuthLayout>
                {(props) => {
                  return <Component {...props} />;
                }}
              </AuthLayout>
            </Route>
          );
        })}
        <Route
          path="/"
          render={() => {
            return <Redirect to="/" />;
          }}
        />
      </Switch>
    );
  }
  return (
    <Switch>
      {pages.map(({ path, component: Component }) => (
        <Route key={path} exact path={path}>
          <MainLayout>{(props) => <Component {...props} />}</MainLayout>
        </Route>
      ))}
      <Route path="*" render={() => <Redirect to="/dashboard" />} />
    </Switch>
  );
};

export default Routes;
