import * as React from "react";
import classNames from "classnames/bind";
import styles from "./Input.module.scss";

const cx = classNames.bind(styles);

interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  containerClassName?: string;
}

const Input: React.FC<InputProps> = ({
  containerClassName,
  className,
  ...inputProps
}) => {
  return (
    <div
      className={cx(
        "py-2 px-4  bg-gray-100 border border-gray-200 rounded-lg flex items-center ",
        containerClassName
      )}
    >
      <input
        {...inputProps}
        className={cx(
          " bg-transparent outline-none placeholder-gray-300::placeholder w-full",
          styles.Input,
          className
        )}
      />
    </div>
  );
};

export default Input;
