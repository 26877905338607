import * as React from "react";
import classNames from "classnames/bind";
import { formatPrice } from "u/formatPrice";
import styles from "./ProgressBar.module.scss";

const cx = classNames.bind(styles);

type ProgressBarProps = {
  from?: number;
  to?: number;
};

const ProgressBar: React.FC<ProgressBarProps> = ({ from, to }) => {
  return (
    <div className={cx(styles.Line)}>
      <div className={styles.Thumb} />
      <div className={styles.MinValue}>${from ? formatPrice(from) : " - "}</div>
      <div className={styles.MaxValue}>${to ? formatPrice(to) : " - "}</div>
    </div>
  );
};

export default ProgressBar;
