import * as React from "react";
import { useFormik } from "formik";
import Button from "ui/Button";
import { ReactComponent as LoginBanner } from "i/LoginBanner.svg";
import Input from "c/Input";
import { ReactComponent as Logo } from "i/logo.svg";
import { useHistory } from "react-router-dom";
import { useLoginMutation } from "generated/graphql";
import { useApolloClient } from "@apollo/client";

// TODO: remember me functionality
// TODO: lock when trying to login

type LoginProps = {};

const Login: React.FC<LoginProps> = () => {
  const history = useHistory();
  const client = useApolloClient();
  const [login] = useLoginMutation();

  const { values, handleSubmit, handleChange } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (fields) => {
      try {
        const { data } = await login({
          variables: {
            input: fields,
          },
        });

        if (!data) throw new Error("Error occured while logging in");

        const loginData = data?.login;
        localStorage.setItem("token", loginData.token);

        client.refetchQueries({
          include: "all",
        });

        history.push("/");
      } catch (e) {
        console.error(e);
      }
    },
  });

  return (
    <>
      <div className="flex items-center justify-center w-1/2 p-8">
        <LoginBanner />
      </div>
      <div className="flex items-center justify-center w-1/2 text-lg">
        <form
          onSubmit={handleSubmit}
          className="flex flex-col items-center justify-center w-full max-w-md px-10 py-20 bg-white border rounded-2xl border-border-gray"
        >
          <h1 className="mb-6 text-3xl font-semibold">Welcome to Micasa</h1>
          <Input
            placeholder="Email address"
            containerClassName="mb-4 w-full"
            name="email"
            onChange={handleChange}
            value={values.email}
          />
          <Input
            type="password"
            placeholder="Password"
            containerClassName="mb-4 w-full"
            name="password"
            onChange={handleChange}
            value={values.password}
          />
          <div className="flex items-center self-stretch my-2 text-sm font-semibold">
            <input
              type="checkbox"
              id="remember_me"
              name="remember_me"
              className="mr-1"
            />
            <label className="select-none" htmlFor="remember_me">
              Remember me
            </label>
            <a href="/recovery" className="ml-auto">
              Forget password?
            </a>
          </div>
          <Button
            className="self-center px-8 my-4 "
            type="submit"
            label="Log in"
          />
          <div className="self-start text-sm ">
            Don't have an account?{" "}
            <a href="/signup" className="font-semibold text-red-500">
              Click here
            </a>
          </div>
        </form>
      </div>
      <Logo className="absolute h-20 bottom-8 right-4" />
    </>
  );
};

export default Login;
