import { Property } from "generated/graphql";

export const getPropertyAddress = ({
  unit,
  streetNumber,
  street,
  suburb,
  city,
  country,
}: Pick<
  Property,
  "unit" | "streetNumber" | "street" | "suburb" | "city" | "country"
>): string =>
  `${unit && `${unit}/`}${streetNumber} ${street}${suburb && `, ${suburb}`}${
    city && `, ${city}`
  }${country && `, ${country}`}`;
