import * as React from "react";
import { DropzoneOptions, FileWithPath, useDropzone } from "react-dropzone";
import classNames from "classnames/bind";
import { FiUpload } from "react-icons/fi";
import Button from "ui/Button";
import styles from "./UploadFile.module.scss";

const cx = classNames.bind(styles);

interface UploadFileProps extends DropzoneOptions {
  className?: string;
  files?: File[] | string[];
}

const UploadFile: React.FC<UploadFileProps> = ({
  className,
  onDrop,
  files = [],
  accept = "image/*,.pdf",
  multiple = false,
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    multiple,
  });
  return (
    <div
      className={cx(
        " rounded-2xl border border-border-gray flex justify-center items-center  w-full p-4 outline-none  cursor-pointer text-very-dark-gray text-center relative",
        className
      )}
      {...getRootProps()}
    >
      <div
        className={cx("flex flex-col items-center", {
          invisible: files.length > 0 || isDragActive,
        })}
      >
        <FiUpload size={50} className="mb-4 text-very-dark-gray" />
        <p className="font-bold text-very-dark-gray">
          Drag & Drop file here to upload
        </p>
        <p>or click to browse files</p>
        <Button label="Browse Files" color="gray" className="mt-4" />
      </div>
      {isDragActive && (
        <div className="flex flex-col items-center justify-center absolute top-0 left-0 w-full h-full">
          <FiUpload size={50} className="mb-4 text-very-dark-gray" />
          <b>Drop file here</b>
        </div>
      )}
      {files.length > 0 && (
        <img
          className="absolute top-0 left-0 w-full h-full object-contain"
          alt={typeof files[0] !== "string" ? files[0].name : ""}
          src={
            typeof files[0] === "string"
              ? files[0]
              : URL.createObjectURL((files as FileWithPath[])[0])
          }
        />
      )}
      <input {...getInputProps()} />
    </div>
  );
};

export default UploadFile;
