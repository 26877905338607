import { gql, useApolloClient } from "@apollo/client";
import * as React from "react";
import { HiCheck } from "react-icons/hi";
import { IoPaperPlaneOutline, IoPersonCircleSharp } from "react-icons/io5";
import { RiErrorWarningLine } from "react-icons/ri";
import { getRoleFields } from "u/getRoleFields";
import classNames from "classnames/bind";
import { formatPrice } from "u/formatPrice";
import { CgSpinnerTwoAlt } from "react-icons/cg";
import { AiOutlineRedo } from "react-icons/ai";
import styles from "./UserRow.module.scss";
import {
  useInviteUserMutation,
  User,
  UserRole,
  UsersQuery,
  useUserQuery,
} from "generated/graphql";

const cx = classNames.bind(styles);

// TODO: we don't have phone of all people including admin
// TODO: show error if email is invalid
function emailIsValid(email: string) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

type UserRowProps = {
  user: UsersQuery["users"]["users"][0];
  selectedUserId?: string;
  setSelectedUserId: (v: string) => void;
};

const UserRow: React.FC<UserRowProps> = ({
  user,
  selectedUserId,
  setSelectedUserId,
}) => {
  const client = useApolloClient();

  const { data: userData } = useUserQuery();
  const currentUser = userData?.user;

  const [inviteUser, { loading: inviteUserLoading }] = useInviteUserMutation();

  const { id, email, name, isInvited, isSignedUp } = user;
  const isEmailValid = emailIsValid(email);

  const isSelectedUser = id === selectedUserId;

  const userFields = getRoleFields(user as User);

  const onInviteUser = async () => {
    await inviteUser({
      variables: {
        id,
      },
    });
    client.writeFragment({
      id: `User:${id}`,
      fragment: gql`
        fragment UserInviteFragment on User {
          isInvited
        }
      `,
      data: {
        isInvited: true,
      },
    });
  };

  const getActionButton = () => {
    if (!isEmailValid || currentUser?.role !== UserRole.Admin) {
      return <RiErrorWarningLine size={25} className="cursor-not-allowed" />;
    }

    if (inviteUserLoading) {
      return <CgSpinnerTwoAlt size={25} className="text-white animate-spin" />;
    }

    if (isInvited && !isSignedUp) {
      return (
        <AiOutlineRedo
          size={25}
          className="text-white cursor-pointer hover:animate-spin"
          onClick={onInviteUser}
        />
      );
    }

    if (isInvited) {
      return <HiCheck size={25} className="text-white" />;
    }

    return (
      <IoPaperPlaneOutline
        size={25}
        className="cursor-pointer"
        onClick={onInviteUser}
      />
    );
  };

  return (
    <div
      key={user.id}
      className={cx(
        "flex items-center  leading-4 transition-all duration-300 hover:bg-white py-4 rounded-xl",
        {
          "bg-white": isSelectedUser,
        }
      )}
      onClick={() => {
        setSelectedUserId(id);
      }}
    >
      {user.avatar ? (
        <img
          src={user.avatar}
          alt={`${name} avatar`}
          className="flex-shrink-0 object-cover w-12 h-12 mx-4 rounded-full"
        />
      ) : (
        <IoPersonCircleSharp size={50} className="flex-shrink-0 mx-4" />
      )}
      <div className="flex-1 mx-8 overflow-hidden">
        <div>
          <div className="font-semibold line-clamp-1">{name || "-"}</div>
          <div className="text-sm line-clamp-1">
            {email ? `${email} ${String.fromCharCode(160)}` : "-"}
          </div>
        </div>
      </div>
      <div className="flex-shrink-0 mx-8 text-center">
        <div className="font-semibold">
          {userFields && "currentBalance" in userFields
            ? formatPrice(userFields.currentBalance ?? 0)
            : "-"}
        </div>
        <div className="text-sm">Current balance</div>
      </div>
      <div className="flex-shrink-0 mx-8 text-center">
        <div className="font-semibold">
          {(userFields && userFields?.propertyCount) ?? "-"}
        </div>
        <div className="text-sm">Active properties</div>
      </div>
      {UserRole.Admin === userData?.user.role && (
        <div
          className={cx(
            "border w-12 h-12 p-2 flex items-center justify-center rounded-xl mx-4 flex-shrink-0",
            {
              "bg-dark-gray border-border-gray":
                !isInvited && !inviteUserLoading,
              "bg-teal border-dark-teal": isInvited || inviteUserLoading,
            }
          )}
        >
          {getActionButton()}
        </div>
      )}
    </div>
  );
};

export default UserRow;
