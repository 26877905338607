import * as React from "react";
import userPlaceholder from "i/user-placeholder.jpeg";
import { IoMdSearch } from "react-icons/io";
import { useSubscription } from "@apollo/client";
import { MESSAGES } from "queries";
import Spinner from "ui/Spinner";
import Button from "ui/Button";
import * as QueryString from "query-string";
import { useHistory } from "react-router-dom";
import classNames from "classnames/bind";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { CHAT_MESSAGE_ADDED } from "subscriptions";
import styles from "./Chat.module.scss";
import AddChatModal from "./AddChatModal";
import TextEditor from "./TextEditor";
import MessageList from "./MessageList";
import {
  useAddMessageToChatMutation,
  useChatsQuery,
  useCreateChatMutation,
  useMessagesLazyQuery,
  useUserQuery,
} from "generated/graphql";

const cx = classNames.bind(styles);

type ChatProps = {};

const Chat: React.FC<ChatProps> = () => {
  const [isAddChatModalOpen, setIsAddChatModalOpen] =
    React.useState<boolean>(false);

  const { data: chatsData, loading, refetch: refetchChats } = useChatsQuery();

  const [addMessageToChat] = useAddMessageToChatMutation();
  const [createChat] = useCreateChatMutation();

  const [
    getMessages,
    {
      data: messagesData,
      variables: messagesVariables,
      loading: messagesLoading,
    },
  ] = useMessagesLazyQuery();

  const { data: userData } = useUserQuery();
  const currentUser = userData?.user;

  const history = useHistory();
  const { chatId }: { chatId?: string } = QueryString.parse(
    history.location.search
  );
  useSubscription(CHAT_MESSAGE_ADDED, {
    variables: { chatId },
    onSubscriptionData: ({ subscriptionData, client }) => {
      // https://github.com/apollographql/apollo-client/issues/6037
      // maybe add a check if it's duplicate message and don't update query if so
      const newMessage = subscriptionData.data.chatMessageAdded;
      if (!messagesData) return;
      client.writeQuery({
        query: MESSAGES,
        variables: { chatId },
        data: { messages: [...messagesData.messages, newMessage] },
      });
    },
  });

  React.useEffect(() => {
    if (!chatId && chatsData?.chats?.length) {
      history.push({
        pathname: "/chat",
        search: `?chatId=${chatsData.chats[0].id}`,
      });
    }
  }, [chatId, chatsData, history]);

  const currentChat = chatsData?.chats?.find((c: any) => c.id === chatId);
  const currentChatUser = currentChat?.users?.find(
    (u: any) => u.id !== currentUser?.id
  );

  React.useEffect(() => {
    if (chatId && (!messagesData || chatId !== messagesVariables?.chatId)) {
      getMessages({
        variables: { chatId },
      });
    }
  }, [chatId, getMessages, messagesData, messagesVariables?.chatId]);

  const onCreateChat = async (userId: string) => {
    const { data: createChatData }: any = await createChat({
      variables: { input: { userId } },
    });
    await refetchChats();
    history.push({
      pathname: "/chat",
      search: `?chatId=${createChatData?.createChat.id}`,
    });
  };

  // TODO: stopped on getting messages for chatId and displaying it
  // probably useEffect with update on chatId

  return (
    <div className="flex flex-1">
      {isAddChatModalOpen && (
        <AddChatModal
          onClose={() => setIsAddChatModalOpen(false)}
          onSelect={onCreateChat}
        />
      )}
      {chatsData?.chats && chatsData?.chats?.length > 0 ? (
        <>
          <div className="w-120 mr-6 max-h-full overflow-hidden flex flex-col">
            <div className="flex items-center pb-4 mb-4 border-border-gray border-b">
              <IoMdSearch size={20} className="mr-2" />
              <input
                className=" bg-transparent outline-none w-32 placeholder-black flex-1"
                placeholder="Search..."
              />
              <AiOutlinePlusCircle
                size={25}
                className="ml-2 cursor-pointer"
                onClick={() => setIsAddChatModalOpen(true)}
              />
            </div>
            <div className="overflow-y-auto max-h-full relative pr-6">
              {loading ? (
                <Spinner />
              ) : (
                chatsData?.chats.map((chat: any) => {
                  const user = chat.users.find(
                    (u: any) => u.id !== currentUser?.id
                  );
                  const isActive = chatId === chat.id;
                  const [message] = chat.messages;

                  return (
                    <div
                      key={chat.id}
                      className={cx(
                        " flex  items-center bg-white p-2 my-4 relative rounded-2xl border border-border-gray transition duration-300 hover:bg-dark-gray max-w-full",
                        {
                          "bg-dark-gray": isActive,
                        }
                      )}
                      onClick={() => {
                        history.push({
                          pathname: "/chat",
                          search: `?chatId=${chat.id}`,
                        });
                      }}
                    >
                      <img
                        className="w-16 h-16 rounded-full mr-2"
                        src={user.avatar ?? userPlaceholder}
                        alt={`${user.name} avatar`}
                      />
                      <div className="flex-1">
                        <div className="font-semibold line-clamp-1">
                          {user.name}
                        </div>
                        <div className="line-clamp-1">
                          {chat.messages[0]?.message ?? "-"}
                        </div>
                      </div>
                      <div className="ml-auto mr-2">1d</div>
                      {message?.isSeen === false && (
                        <div className="bg-dark-pink w-4 h-4 rounded-full mr-2" />
                      )}
                    </div>
                  );
                })
              )}
            </div>
          </div>
          <div className="flex-1 flex flex-col overflow-hidden">
            {currentChat ? (
              <>
                <div className="flex items-center">
                  <img
                    className="w-16 h-16 rounded-full mr-2"
                    src={currentChatUser?.avatar ?? userPlaceholder}
                    alt={`${currentChatUser?.name} avatar`}
                  />
                  <div className="font-semibold">{currentChatUser?.name}</div>
                </div>
                {messagesLoading ? (
                  <Spinner />
                ) : (
                  <MessageList
                    messages={(messagesData?.messages as any) ?? ([] as any)}
                  />
                )}
                <TextEditor
                  addMessageToChat={async (input) => {
                    // subscription will update chat
                    await addMessageToChat({
                      variables: {
                        input: { ...input, chatId: chatId ?? "" },
                      },
                    });
                  }}
                />
              </>
            ) : (
              <Spinner />
            )}
          </div>
        </>
      ) : (
        <Button
          label="Start New Chat"
          className="m-auto"
          onClick={() => setIsAddChatModalOpen(true)}
        />
      )}
    </div>
  );
};

export default Chat;
