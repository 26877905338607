import * as React from "react";
import classNames from "classnames/bind";
import styles from "./SelectOfficeOverlay.module.scss";
import { useOfficeQuery, useOfficesQuery } from "generated/graphql";
import { useApolloClient, useReactiveVar } from "@apollo/client";
import { currentOfficeVar } from "index";

const cx = classNames.bind(styles);

type SelectOfficeOverlayProps = {};

const SelectOfficeOverlay: React.FC<SelectOfficeOverlayProps> = () => {
  const client = useApolloClient();
  const currentOfficeId = useReactiveVar(currentOfficeVar);
  const { data: officesData } = useOfficesQuery();
  const offices = officesData?.offices;

  const { data: officeData } = useOfficeQuery({
    skip: !currentOfficeId,
  });
  const currentOffice = officeData?.office;

  return (
    <div>
      <div className="font-semibold text-lg">Select office</div>
      <div className="text-gray-400">
        {offices?.map(({ address, id }) => (
          <div
            key={id}
            onClick={() => {
              localStorage.setItem("location", id);
              currentOfficeVar(id);
              client.refetchQueries({
                include: "all",
              });
            }}
            className={cx(" font-semibold cursor-pointer", {
              "text-black": id === currentOffice?.id,
            })}
          >
            {address}
          </div>
        )) ?? <div>Loading</div>}
      </div>
    </div>
  );
};

export default SelectOfficeOverlay;
