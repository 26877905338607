import * as React from "react";
import classNames from "classnames/bind";
import styles from "./Textarea.module.scss";

const cx = classNames.bind(styles);

interface TextareaProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {}

const Textarea: React.FC<TextareaProps> = ({ className, ...TextareaProps }) => {
  return (
    <textarea
      {...TextareaProps}
      className={cx(
        "h-32 p-4 border border-gray-300 rounded-2xl outline-none ",
        className
      )}
    />
  );
};

export default Textarea;
