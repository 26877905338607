import * as React from "react";
import { Tooltip } from "react-tippy";
import { AiFillInfoCircle } from "react-icons/ai";
import "react-tippy/dist/tippy.css";
import classNames from "classnames/bind";
import styles from "./HelpButton.module.scss";

const cx = classNames.bind(styles);

type HelpButtonProps = {
  className?: string;
  text: string;
};

const HelpButton: React.FC<HelpButtonProps> = ({ className, text }) => {
  return (
    <Tooltip title={text} position="bottom-start" trigger="mouseenter">
      <AiFillInfoCircle
        className={cx(
          className,
          "text-2xl absolute right-5 top-5 text-blue-gray"
        )}
      />
    </Tooltip>
  );
};

export default HelpButton;
