import { UserRole } from "generated/graphql";
import { IUser } from "interfaces";
import * as React from "react";
import { AiOutlinePrinter } from "react-icons/ai";
import { BiGlassesAlt } from "react-icons/bi";

type AddOverlayFloatingMenuProps = {
  setIsInspectionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAddOfficeModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentUser: IUser;
};

const AddOverlayFloatingMenu: React.FC<AddOverlayFloatingMenuProps> = ({
  setIsInspectionModalOpen,
  setIsAddOfficeModalOpen,
  currentUser,
}) => {
  return (
    <div>
      <div
        className="flex items-center my-2 cursor-pointer"
        onClick={() => setIsInspectionModalOpen(true)}
      >
        <BiGlassesAlt className="mr-4" />
        <div>Add Inspection</div>
      </div>
      {[UserRole.Admin].includes(currentUser.role) && (
        <div
          className="flex items-center my-2 cursor-pointer"
          onClick={() => setIsAddOfficeModalOpen(true)}
        >
          <AiOutlinePrinter className="mr-4" />
          <div>Add office</div>
        </div>
      )}
    </div>
  );
};

export default AddOverlayFloatingMenu;
