import * as React from "react";
import palaceLogo from "i/palace-logo.png";
import propertyMeLogo from "i/propertyme-logo.png";
import xeroLogo from "i/xero-logo.png";
import propertyTreeLogo from "i/propertytree-logo.png";
import vaultreLogo from "i/vaultre-logo.png";
import tapiLogo from "i/tapi-logo.png";
import revuLogo from "i/revu-logo.png";
import Button from "ui/Button";
import _ from "lodash";
import classNames from "classnames/bind";
import AddEditPalace from "./AddEditPalace";
import styles from "./Integrations.module.scss";
import { Office, useOfficeQuery } from "generated/graphql";
import { useReactiveVar } from "@apollo/client";
import { currentOfficeVar } from "index";

const cx = classNames.bind(styles);

type IntegrationsProps = {};

interface IIntegration {
  logo: string;
  name: string;
  slug: string;
  status: string;
}

const integrationList: IIntegration[] = [
  {
    logo: palaceLogo,
    name: "Palace",
    slug: "palace",
    status: "active",
  },
  {
    logo: propertyMeLogo,
    name: "PropertyMe",
    slug: "propertyme",
    status: "Coming Soon...",
  },
  {
    logo: xeroLogo,
    name: "Xero",
    slug: "xero",
    status: "Coming Soon...",
  },
  {
    logo: propertyTreeLogo,
    name: "Property Three",
    slug: "property-three",
    status: "Coming Soon...",
  },
  {
    logo: vaultreLogo,
    name: "VaultRE",
    slug: "vaultre",
    status: "Coming Soon...",
  },
  {
    logo: tapiLogo,
    name: "Tapi",
    slug: "tapi",
    status: "Coming Soon...",
  },
  {
    logo: revuLogo,
    name: "Revu",
    slug: "revu",
    status: "Coming Soon...",
  },
];
// TODO: temporary solution
const Integrations: React.FC<IntegrationsProps> = () => {
  // const { currentOffice } = useCompany();
  const currentOfficeId = useReactiveVar(currentOfficeVar);

  const { data } = useOfficeQuery({
    skip: !currentOfficeId,
  });

  const currentOffice = data?.office;

  const [isPalaceModalOpen, setIsPalaceModalOpen] = React.useState(false);

  return (
    <div className="flex-1">
      {isPalaceModalOpen && (
        <AddEditPalace onClose={() => setIsPalaceModalOpen(false)} />
      )}
      <h1 className="font-semibold text-2xl mb-6">Integrations</h1>
      <div className=" grid grid-cols sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5  gap-12 flex-1 self-start">
        {integrationList.map(({ slug, status, logo, name }) => {
          let buttons = <Button className=" mt-4" label="Connect" />;
          //TODO: fix the keyof type
          if (currentOffice?.[slug as keyof Pick<Office, "palace">]) {
            buttons = (
              <div className="w-full flex justify-around">
                <Button
                  className=" mt-4"
                  label="Edit"
                  onClick={() => setIsPalaceModalOpen(true)}
                />
              </div>
            );
          }

          const bgClassName =
            !status || status === "Coming Soon..."
              ? "bg-dark-gray"
              : "bg-white";

          return (
            <div
              key={slug}
              className={cx(
                bgClassName,
                "rounded-3xl flex flex-col  items-center p-8 border border-border-gray"
              )}
            >
              {logo && <img src={logo} alt={name} className="h-24" />}
              <div className="font-bold text-3xl my-4 text-center line-clamp-2 h-16">
                {name}
              </div>
              <div>{_.capitalize(status)}</div>
              {buttons}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Integrations;
