import * as React from "react";
import classNames from "classnames/bind";
import { FiEdit2 } from "react-icons/fi";
import { format } from "date-fns";
import PropertyPlaceholder from "i/property-placeholder.jpg";
import Button from "ui/Button";
import Spinner from "ui/Spinner";
import { getPropertyAddress } from "u/getPropertyAddress";
import InspectionModal from "c/InspectionModal";
import AddEditInspection from "l/MainLayout/AddEditInspection";
import { Link } from "react-router-dom";
import styles from "./Inspections.module.scss";
import {
  Inspection,
  useInspectionsQuery,
  UserRole,
  useUserQuery,
} from "generated/graphql";

const cx = classNames.bind(styles);

type InspectionsProps = {
  propertyId?: string;
  className?: string;
  showPropertyImage?: boolean;
};

//TODO: make padding at the bottom
const Inspections: React.FC<InspectionsProps> = ({
  propertyId,
  className,
  showPropertyImage = true,
}) => {
  const [openedInspectionId, setOpenedInspectionId] = React.useState<
    string | null
  >(null);
  const [editingInspectionId, setEditingInspectionId] = React.useState<
    string | null
  >(null);

  const { data: userData } = useUserQuery();
  const currentUser = userData?.user;

  const { data, loading } = useInspectionsQuery({
    variables: {
      propertyId,
      pagination: {},
    },
  });

  let content;

  if (loading) content = <Spinner />;
  if (data?.inspections?.inspections.length === 0) {
    content = (
      <div className="flex items-center justify-center col-span-2 text-3xl text-center">
        <div>No Inspections Yet</div>
      </div>
    );
  }

  const openedInspection =
    openedInspectionId &&
    data?.inspections?.inspections.find((i) => i.id === openedInspectionId);

  const editingInspection = data?.inspections?.inspections.find(
    (i) => i.id === editingInspectionId
  );
  if (
    data?.inspections?.inspections.length &&
    data.inspections.inspections.length > 0
  ) {
    content = (
      <div className={cx(className, "overflow-y-auto pr-4")}>
        {data?.inspections?.inspections.map(
          ({ date, id, issues, property, propertyManager, type }) => {
            const issueLabel =
              issues.length === 0
                ? "No Issues"
                : `${issues.length} issue${issues.length > 1 ? "s" : ""}`;

            const canEditInspection =
              currentUser?.role === UserRole.Admin ||
              (propertyManager?.id &&
                currentUser?.role === UserRole.PropertyManager &&
                currentUser?.propertyManager?.id === propertyManager.id);

            return (
              <div key={id} className="flex items-center py-4 ">
                {showPropertyImage && (
                  <Link to={`/properties/${id}`}>
                    <img
                      src={property.images?.[0]?.url ?? PropertyPlaceholder}
                      className="flex-shrink-0 w-16 h-16 mr-4 rounded-full"
                      alt={property.name}
                    />
                  </Link>
                )}
                <div className="flex-1 pr-4">
                  <Link to={`/properties/${id}`}>
                    <div className="font-bold line-clamp-1 overflow-ellipsis">
                      {getPropertyAddress(property)}
                    </div>
                  </Link>
                  <div className="text-gray-400">
                    {propertyManager?.user?.name ?? "-"}
                  </div>
                </div>
                <div className="w-32 font-semibold text-very-dark-gray">
                  {type}
                </div>
                <div className="flex-shrink-0 w-24 ml-auto text-center">
                  <div
                    className={cx("whitespace-nowrap ", {
                      "text-very-dark-gray font-semibold": issues.length === 0,
                      "text-pink font-bold": issues.length > 0,
                    })}
                  >
                    {issueLabel}
                  </div>
                  <div className="text-sm text-gray-400">
                    {format(new Date(date), "dd/MM/yyyy hh:mm aa")}
                  </div>
                </div>
                <Button
                  onClick={() => {
                    setOpenedInspectionId(id);
                  }}
                  label="View"
                  className="flex-shrink-0 ml-10 mr-6"
                />
                <div
                  className={cx(
                    "bg-dark-gray border border-border-gray w-12 h-12 rounded-xl flex items-center justify-center",
                    {
                      hidden: !canEditInspection,
                    }
                  )}
                >
                  <FiEdit2
                    size={25}
                    className={cx("mx-4 flex-shrink-0 cursor-pointer")}
                    onClick={() => setEditingInspectionId(id)}
                  />
                </div>
              </div>
            );
          }
        )}
      </div>
    );
  }

  return (
    <>
      {editingInspectionId && (
        <AddEditInspection
          onClose={() => setEditingInspectionId(null)}
          inspection={editingInspection as Inspection | undefined}
        />
      )}
      <div
        className={cx(
          "relative col-span-2 overflow-y-hidden w-full p-10  rounded-2xl border border-border-gray  max-h-80",
          styles.Inspections,
          {
            [styles.Loading]: loading,
          }
        )}
      >
        {openedInspectionId && (
          <InspectionModal
            onClose={() => setOpenedInspectionId(null)}
            inspection={openedInspection as Inspection}
          />
        )}
        {content}
      </div>
    </>
  );
};

export default Inspections;
