import * as React from "react";
import { IconType } from "react-icons/lib";
import ReactSelect, {
  OptionTypeBase,
  Props,
  Styles,
  ValueContainerProps,
  components,
} from "react-select";

const ValueContainer: React.FC<ValueContainerProps<OptionTypeBase, false>> = ({
  children,
  ...props
}) => {
  const { selectProps } = props;
  const Icon = selectProps.icon ?? null;

  return (
    <components.ValueContainer {...props}>
      {Icon && <Icon className="mr-2" />}
      {children}
    </components.ValueContainer>
  );
};

const customStyles: Styles<OptionTypeBase, false> = {
  valueContainer: (provided) => {
    return {
      ...provided,
      width: "100%",
      display: "flex",
      alignItems: "center",
    };
  },
  container: (provided) => ({ ...provided, flex: 1 }),
  control: (provided) => ({
    ...provided,
    "--tw-bg-opacity": 1,
    background: "rgba(243, 244, 246, var(--tw-bg-opacity))",
    "--tw-border-opacity": 1,
    borderColor: "rgba(229, 231, 235, var(--tw-border-opacity))",
  }),
  placeholder: (provided, state) => {
    return {
      ...provided,
      position: "static",
      transform: "none",
      display: state.isFocused ? "none" : "block",
      color: "black",
    };
  },
};

interface SelectProps extends Props<OptionTypeBase> {
  icon?: IconType;
  width?: number | string;
}

const Select: React.FC<SelectProps> = ({ icon, width, ...selectProps }) => {
  return (
    <ReactSelect
      {...selectProps}
      styles={customStyles}
      components={{
        ValueContainer,
      }}
      icon={icon}
      width={width}
      menuPortalTarget={document.querySelector("body")}
    />
  );
};

export default Select;
