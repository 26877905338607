import WorkOrderModal from "c/WorkOrderModal";
import { format } from "date-fns";
import { useWorkOrdersQuery } from "generated/graphql";
import { IWorkOrder } from "interfaces";
import * as React from "react";
import ReactPaginate from "react-paginate";
import { getPropertyAddress } from "u/getPropertyAddress";
import Button from "ui/Button";
import Spinner from "ui/Spinner";

type WorkOrdersProps = {};

const WorkOrders: React.FC<WorkOrdersProps> = () => {
  const [openedWordOrderId, setOpenedWordOrderId] = React.useState<
    string | null
  >(null);

  const [currentPage, setCurrentPage] = React.useState(1);

  const { data, loading, refetch } = useWorkOrdersQuery({
    variables: {
      pagination: {
        limit: 20,
      },
    },
  });

  React.useEffect(() => {
    refetch({
      pagination: {
        limit: 20,
        page: currentPage,
      },
    });
  }, [currentPage, refetch]);

  if (loading) return <Spinner />;
  const openedWorkOrder =
    openedWordOrderId &&
    data &&
    data?.workOrders.workOrders.find((i) => i.id === openedWordOrderId);

  if (!data) throw new Error("Error occured");

  return (
    <div className="flex flex-col flex-1">
      {openedWordOrderId && (
        <WorkOrderModal
          onClose={() => setOpenedWordOrderId(null)}
          workOrder={openedWorkOrder as IWorkOrder}
        />
      )}
      {!data?.workOrders.workOrders.length ||
      data.workOrders.workOrders.length === 0 ? (
        <div className="flex items-center justify-center flex-1 col-span-2 text-3xl text-center">
          <div>No Work Orders Yet</div>
        </div>
      ) : (
        <>
          <div className="flex px-4 font-bold">
            <div className="flex-shrink-0 mr-4 flex-2 ">Issue</div>
            <div className="flex-shrink-0 mr-4 flex-5">Address</div>
            <div className="flex-shrink-0 mr-4 flex-5 w-44">Description</div>
            <div className="flex-shrink-0 mr-4 flex-2 w-44">Date</div>
            <div className="w-20" />
          </div>
          <div className="flex-1 pr-8 overflow-auto ">
            {data.workOrders.workOrders.map(
              ({ description, id, expense, property, scheduledDate }) => {
                return (
                  <div
                    key={id}
                    className="flex items-center px-4 py-4 transition-all duration-300 hover:bg-white rounded-xl"
                  >
                    <div className="flex-shrink-0 mr-4 text-left flex-2">
                      {(expense.name || expense.description) ?? "-"}
                    </div>
                    <div className="flex-shrink-0 mr-4 text-gray-400 flex-5 line-clamp-1">
                      {property ? getPropertyAddress(property) : ""}
                    </div>
                    <div className="flex-shrink-0 mr-4 flex-5 w-44 line-clamp-1 ">
                      {description}
                    </div>
                    <div className="flex-shrink-0 mr-4 flex-2 w-44">
                      {scheduledDate
                        ? format(new Date(scheduledDate), "dd/MM/yyyy")
                        : "-"}
                    </div>

                    <Button
                      label="View"
                      className="w-20"
                      onClick={() => setOpenedWordOrderId(id)}
                    />
                  </div>
                );
              }
            )}
          </div>
        </>
      )}
      <ReactPaginate
        pageCount={data.workOrders.pagination.totalPages}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        containerClassName="flex mx-auto mt-4 select-none"
        activeClassName="font-bold"
        previousLabel=""
        nextLabel=""
        pageClassName="mx-1"
        breakClassName="mx-1"
        pageLinkClassName="outline-none"
        breakLinkClassName="outline-none"
        initialPage={0}
        onPageChange={({ selected }) => setCurrentPage(selected + 1)}
      />
    </div>
  );
};

export default WorkOrders;
