import * as React from "react";
import { getPropertyAddress } from "u/getPropertyAddress";
import Modal, { ModalProps } from "ui/Modal/Modal";
import { format } from "date-fns";
import FileList from "c/FileList";
import { Inspection } from "generated/graphql";

const getYoutubeVideoId = (url: string) => {
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
};

const getNumberWithOrdinal = (n: number) => {
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

interface InspectionModalProps extends ModalProps {
  inspection: Inspection;
}

const InspectionModal: React.FC<InspectionModalProps> = ({
  onClose,
  inspection,
}) => {
  return (
    <Modal {...{ onClose }}>
      <div className="flex h-full overflow-y-auto">
        <div className="flex-1 flex-shrink-0 w-1/2 pr-6">
          <h2 className="title ">Inspection Details</h2>
          <div className="flex mb-4">
            <div className="flex-shrink-0 font-medium w-36">Inspected by</div>
            <div>{inspection.propertyManager?.user?.name ?? "-"}</div>
          </div>
          <div className="flex mb-4">
            <div className="flex-shrink-0 font-medium w-36">Inspected type</div>
            <div>{inspection.type}</div>
          </div>
          <div className="flex mb-4">
            <div className="flex-shrink-0 font-medium w-36">Address</div>
            <div>{getPropertyAddress(inspection.property)}</div>
          </div>
          <div className="mb-4">
            <div className="flex-shrink-0 font-medium w-36">Issues Found</div>
            {inspection.issues.map(
              ({ description, attachments }, i: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={i}>
                  <div>{getNumberWithOrdinal(i + 1)} issue</div>
                  <div className="">
                    <div className="font-semibold">Description: </div>
                    <div>{description}</div>
                  </div>
                  <div className="font-semibold">Issues Attachments:</div>
                  <FileList canDownload canEdit={false} files={attachments} />
                </div>
              )
            )}
          </div>
          <div className="flex mb-4">
            <div className="flex-shrink-0 font-medium w-36">Date and time</div>
            <div>
              {format(new Date(inspection.date), "hh:mmaa dd MMM yyyy")}
            </div>
          </div>
          <div className="">
            <div className="flex-shrink-0 mb-2 font-medium w-36">
              Inspection Info
            </div>
            <pre className="p-2 overflow-y-auto text-gray-500 whitespace-pre-wrap bg-gray-100 rounded-lg h-36">
              {inspection.description}
            </pre>
          </div>
        </div>
        {}
        <div className="flex-1 flex-shrink-0 w-1/2 pl-6">
          {inspection.matterportUrl && (
            <div className="relative h-0 mx-6 mb-4 pb-9/16">
              <iframe
                title="matterport"
                className="absolute top-0 left-0 w-full h-full rounded-xl"
                allowFullScreen
                src={inspection.matterportUrl}
              />
            </div>
          )}
          {inspection.youtubeUrl && !inspection.matterportUrl && (
            <div className="relative h-0 mx-6 mb-4 pb-9/16">
              <iframe
                className="absolute top-0 left-0 w-full h-full rounded-xl"
                src={`https://www.youtube.com/embed/${getYoutubeVideoId(
                  inspection.youtubeUrl
                )}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="video"
              />
            </div>
          )}

          <div className="my-2 font-medium">Files</div>
          <FileList
            canDownload
            canEdit={false}
            files={inspection.attachments ?? []}
          />
        </div>
      </div>
    </Modal>
  );
};

export default InspectionModal;
