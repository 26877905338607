import { messageFields, userFields } from "fragments";
import { gql } from "@apollo/client";

export const CHAT_MESSAGE_ADDED = gql`
  subscription ChatMessageAdded($chatId: ID!) {
    chatMessageAdded(chatId: $chatId) {
      ...MessageFields
      user {
        ...UserFields
      }
    }
  }
  ${messageFields}
  ${userFields}
`;
