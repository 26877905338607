import * as React from "react";
import { GrFormClose } from "react-icons/gr";
import classNames from "classnames/bind";
import styles from "./Modal.module.scss";

const cx = classNames.bind(styles);

export interface ModalProps {
  onClose: () => void;
  className?: string;
}

const Modal: React.FC<ModalProps> = ({ onClose, className, children }) => {
  return (
    <div className={cx(styles.ModalWrapper)}>
      <div
        className={cx(styles.Modal, "p-10")}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <GrFormClose
          onClick={onClose}
          size={30}
          className="absolute cursor-pointer top-3 right-3"
        />
        <div className={className}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
