import Input from "c/Input";
import { useFormik } from "formik";
import { useSendResetPasswordEmailMutation } from "generated/graphql";
import * as React from "react";
import Button from "ui/Button";
import Spinner from "ui/Spinner";

type RecoverAccountProps = {};

const RecoverAccount: React.FC<RecoverAccountProps> = () => {
  const [sendResetPasswordEmail] = useSendResetPasswordEmailMutation();
  const [emailSent, setEmailSent] = React.useState<boolean>(false);
  const { values, handleChange, handleSubmit, isSubmitting } = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async (data) => {
      console.log(data);

      if (!data.email || data.email == "") {
        throw new Error("Email not provided");
      }

      try {
        const { data: responseData } = await sendResetPasswordEmail({
          variables: {
            email: data.email,
          },
        });
      } catch (error) {
        console.error(error);
        return;
      }
      setEmailSent(true);
    },
  });

  return (
    <div className="flex flex-col">
      {emailSent == false && !isSubmitting && (
        <>
          <p>
            Please enter your email below, if an account exists with this email
            a password reset link will be emailed to you.
          </p>
          <form
            className="flex flex-col items-center justify-center mt-4"
            onSubmit={handleSubmit}
          >
            <Input
              value={values.email}
              containerClassName="mb-4"
              name="email"
              placeholder="Email"
              onChange={handleChange}
            />
            <Button label="Submit" type="submit" disabled={isSubmitting} />
          </form>
        </>
      )}
      {isSubmitting && !emailSent && (
        <>
          <Spinner />
        </>
      )}
      {emailSent && !isSubmitting && (
        <>
          <p>Complete. Thank you!</p>
        </>
      )}
    </div>
  );
};

export default RecoverAccount;
