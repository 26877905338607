import * as React from "react";
import classNames from "classnames/bind";
import Spinner from "ui/Spinner";
import styles from "./Users.module.scss";
import SelectedUser from "./SelectedUser";
import { User, UserRole, useUserQuery, useUsersQuery } from "generated/graphql";
import UserRow from "./UserRow";
import ReactPaginate from "react-paginate";

const cx = classNames.bind(styles);

type UsersProps = {};

const Users: React.FC<UsersProps> = () => {
  const [currentPage, setCurrentPage] = React.useState(1);

  const { data: userData } = useUserQuery();
  const currentUser = userData?.user;

  const [selectedRole, setSelectedRole] = React.useState(UserRole.Landlord);
  const [selectedUserId, setSelectedUserId] = React.useState(currentUser?.id);

  const { data: usersData, refetch } = useUsersQuery({
    variables: {
      role: selectedRole,
      pagination: {
        page: 1,
        limit: 20,
      },
    },
  });
  const users = usersData?.users.users;
  const pagination = usersData?.users.pagination;

  const rolesList: UserRole[] = React.useMemo(() => {
    let roles = [
      UserRole.Landlord,
      UserRole.Tenant,
      UserRole.PropertyManager,
      UserRole.Admin,
    ];
    if (userData?.user.role === UserRole.Landlord) {
      roles = [UserRole.PropertyManager];
    }
    if (userData?.user.role === UserRole.PropertyManager) {
      roles = [UserRole.Landlord, UserRole.Tenant];
    }

    return roles;
  }, [userData?.user.role]);

  // upddate selectedRole if not admin
  React.useEffect(() => {
    if (!userData?.user.role || rolesList.includes(selectedRole)) return;
    setSelectedRole(rolesList[0]);
  }, [rolesList, selectedRole, userData?.user.role]);

  React.useEffect(() => {
    if (!selectedUserId && currentUser) {
      setSelectedUserId(currentUser.id);
    }
  }, [currentUser, selectedUserId]);

  React.useEffect(() => {
    refetch({
      role: selectedRole,
      pagination: {
        limit: 20,
        page: currentPage,
      },
    });
  }, [currentPage, refetch, selectedRole]);

  if (!users) return <Spinner />;

  const selectedUser =
    users.find((u) => u.id === selectedUserId) ?? currentUser;

  return (
    <div className={cx("flex-1  w-full flex")}>
      <div className="flex flex-col w-full pr-8 mr-4 flex-2">
        <div className="flex mb-2 text-gray-400">
          {rolesList.map((role) => {
            return (
              <div
                key={role}
                className={classNames("mx-4 cursor-pointer", {
                  "underline font-bold text-black": role === selectedRole,
                })}
                onClick={() => setSelectedRole(role)}
              >
                {role}
              </div>
            );
          })}
        </div>
        <div className="flex-1 overflow-auto">
          {users.map((user) => {
            return (
              <UserRow
                key={user.id}
                {...{ selectedUserId, setSelectedUserId, user }}
              />
            );
          })}
        </div>
        <ReactPaginate
          pageCount={pagination?.totalPages ?? 0}
          pageRangeDisplayed={2}
          marginPagesDisplayed={1}
          containerClassName="flex mx-auto mt-4 select-none"
          activeClassName="font-bold"
          previousLabel=""
          nextLabel=""
          pageClassName="mx-1"
          breakClassName="mx-1"
          pageLinkClassName="outline-none"
          breakLinkClassName="outline-none"
          initialPage={0}
          onPageChange={({ selected }) => setCurrentPage(selected + 1)}
        />
      </div>
      <SelectedUser user={selectedUser as User} />
    </div>
  );
};

export default Users;
