import { gql } from "@apollo/client";

export const FULL_LOCAL_STATE = gql`
  query fullLocalState {
    errors @client
    currentUser @client
    currentCompany @client
    companyOffices @client
  }
`;

export const GET_CLIENT_OFFICES = gql`
  query {
    companyOffices @client
  }
`;

export const GET_CLIENT_COMPANY = gql`
  query {
    currentCompany @client
  }
`;

export const GET_CLIENT_OFFICE = gql`
  query {
    currentOffice @client
  }
`;

export const GET_CLIENT_USER = gql`
  query {
    currentUser @client
  }
`;
