import React from "react";
import ReactDOM from "react-dom";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  makeVar,
  split,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";
import { FULL_LOCAL_STATE } from "queries.local";
import SwiperCore, {
  A11y,
  Autoplay,
  Controller,
  EffectCoverflow,
  EffectCube,
  EffectFade,
  EffectFlip,
  HashNavigation,
  History,
  Keyboard,
  Lazy,
  Mousewheel,
  Navigation,
  Pagination,
  Parallax,
  Scrollbar,
  Thumbs,
  Virtual,
  Zoom,
} from "swiper";
import "swiper/swiper.scss";
import "swiper/components/a11y/a11y.scss";
import "swiper/components/controller/controller.scss";
import "swiper/components/effect-fade/effect-fade.scss";
import "swiper/components/effect-cube/effect-cube.scss";
import "swiper/components/effect-flip/effect-flip.scss";
import "swiper/components/lazy/lazy.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import "swiper/components/thumbs/thumbs.scss";
import "swiper/components/zoom/zoom.scss";
import "./index.scss";
import { getMainDefinition } from "@apollo/client/utilities";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

SwiperCore.use([
  Virtual,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
  Parallax,
  Zoom,
  Lazy,
  Controller,
  A11y,
  History,
  HashNavigation,
  Autoplay,
  EffectFade,
  EffectCube,
  EffectFlip,
  EffectCoverflow,
  Thumbs,
]);

export const errorsVar = makeVar<string[]>([]);
export const currentOfficeVar = makeVar<string | null>(
  localStorage.getItem("office")
);

const cache = new InMemoryCache({
  typePolicies: {
    User: {
      merge: true,
    },
    Property: {
      merge: true,
    },
    Message: {
      merge: true,
    },
  },
});

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  const office = localStorage.getItem("office");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ?? "",
      "x-micasa-office-id": office ?? "",
    },
  };
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_WS_ENDPOINT as string,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: async () => {
      const token = localStorage.getItem("token");
      const office = localStorage.getItem("office");
      return {
        authorization: token ?? "",
        "x-micasa-office-id": office ?? "",
      };
    },
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const errors: string[] = [];

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message === "Forbidden resource" || path?.includes("invite")) {
        return;
      }
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      graphQLErrors
        .filter(({ path }) => !path?.includes("checkReferralCode"))
        .forEach(({ message }) => errors.push(message));
    });
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
    errors.push(networkError.message);
  }
  errorsVar(errors);
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  ApolloLink.from([errorLink, authLink, uploadLink as unknown as ApolloLink])
);

const client = new ApolloClient({
  cache,
  link: splitLink,
  connectToDevTools: true,
});

const data = {
  errors: [],
  currentUser: null,
  currentCompany: null,
  companyOffices: [],
  currentOffice: null,
};

cache.writeQuery({
  query: FULL_LOCAL_STATE,
  data,
});

client.onResetStore(async () =>
  cache.writeQuery({
    query: FULL_LOCAL_STATE,
    data: {
      ...data,
    },
  })
);

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
