import * as React from "react";
import Button from "ui/Button";
import Modal from "ui/Modal";
import { ReactComponent as ErrorImage } from "i/error-illustration.svg";
import classNames from "classnames/bind";
import styles from "./ErrorsModal.module.scss";

const cx = classNames.bind(styles);

type ErrorsModalProps = {
  onClose: () => void;
  errors: string[];
};

const ErrorsModal: React.FC<ErrorsModalProps> = ({ onClose, errors }) => {
  const okayButtonRef = React.createRef<HTMLButtonElement>();

  const handleKeyDown = React.useCallback(
    (event) => {
      const keyboardEvent = event as React.KeyboardEvent;
      if (keyboardEvent.key === "Enter") {
        okayButtonRef.current?.focus();
        onClose();
      }
    },
    [onClose, okayButtonRef]
  );

  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    // cleanup this component
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <Modal onClose={onClose} className={cx(styles.ErrorsModal)}>
      <ErrorImage className="max-w-full w-64 mb-4 h-auto" />
      <ul className="mb-4 font-bold text-center">
        {errors.map((error) => {
          return <li key={error}>{error}</li>;
        })}
      </ul>
      <Button
        label="Okay"
        className="mt2"
        onClick={onClose}
        ref={okayButtonRef}
      />
    </Modal>
  );
};

export default ErrorsModal;
