import * as React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { AiOutlineHome } from "react-icons/ai";
import { BiArrowBack, BiGlassesAlt } from "react-icons/bi";
import { RiBookletLine, RiDashboardLine } from "react-icons/ri";
import { IoPeopleOutline, IoPersonCircleSharp } from "react-icons/io5";
import { CgArrowsExchange } from "react-icons/cg";
import classNames from "classnames/bind";
import { useHistory } from "react-router-dom";
import styles from "./Menu.module.scss";

const cx = classNames.bind(styles);

type MenuProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const mockMenuItems = [
  {
    label: "Dashboard",
    icon: RiDashboardLine,
    path: "/dashboard",
  },
  {
    label: "Properties",
    icon: AiOutlineHome,
    path: "/properties",
  },
  {
    label: "Inspections",
    icon: BiGlassesAlt,
    path: "/inspections",
  },
  {
    label: "Work Orders",
    icon: RiBookletLine,
    path: "/work-orders",
  },
  {
    label: "Landlords",
    icon: IoPeopleOutline,
    path: "/landlords",
  },
  {
    label: "Integrations",
    icon: CgArrowsExchange,
    path: "/integrations",
  },
];

const Menu: React.FC<MenuProps> = ({ isOpen, setIsOpen }) => {
  const history = useHistory();
  const onClose = () => setIsOpen(false);

  return (
    <>
      <div
        className={cx(
          "bg-black  fixed top-0 left-0 right-0 bottom-0 cursor-pointer duration-300 ease-in-out",
          styles.Overlay,
          {
            [`opacity-0 -z-10 ${styles.OverlayHidden}`]: !isOpen,
            [`opacity-50 z-10 ${styles.Overlay}`]: isOpen,
          }
        )}
      />
      <OutsideClickHandler onOutsideClick={onClose}>
        <div
          className={cx(
            "fixed flex flex-col top-0 left-0 bottom-0 px-4 py-2 bg-white z-20 transform  transition-all duration-300 ease-in-out",
            {
              "-translate-x-full": !isOpen,
            }
          )}
        >
          {/* Container to match header height */}
          <div className=" h-16 flex items-center">
            <BiArrowBack
              size={30}
              className="cursor-pointer"
              onClick={onClose}
            />
          </div>
          {mockMenuItems.map(({ label, icon: Icon, path }) => {
            return (
              <div
                key={path}
                onClick={() => {
                  history.push(path);
                  onClose();
                }}
                className="select-none cursor-pointer flex items-center py-2 px-4 my-6 mx-4 transition duration-300 rounded-lg hover:bg-gray-200"
              >
                <Icon className="mr-4" />
                <div>{label}</div>
              </div>
            );
          })}
          <div className="mt-auto mb-12 mx-auto">
            <IoPersonCircleSharp size={80} className="mx-auto" />
            <div className="font-bold">Cole Smith</div>
          </div>
        </div>
      </OutsideClickHandler>
    </>
  );
};

export default Menu;
